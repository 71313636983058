import React from 'react';

import IntegrationStatus from '@/compositions/IntegrationStatus';
import {
  IntegrationContextProvider,
  IntegrationData,
  IntegrationProvider,
  useIntegrationContext,
} from '@/context/IntegrationContext';
import { Popover } from 'antd';

import { IntegrationLeds, IntegrationStatusLed, Container } from './styles';
import { IntegrationStatusesProps } from './types';

export const withIntegrationProvider = (Component: React.FC<any>) => (props: IntegrationStatusesProps) => {
  return (
    <IntegrationContextProvider>
      <Component {...props} />
    </IntegrationContextProvider>
  );
};

export const IntegrationStatuses = withIntegrationProvider(
  React.memo(({ isMini }: IntegrationStatusesProps) => {
    const { datasul, serasa, sap, custody } = useIntegrationContext();

    const getLedStatus = (integrationData: IntegrationData) => {
      if (integrationData.loading) return 'loading';
      return integrationData.status ? 'on' : 'off';
    };

    return (
      <div>
        <Popover
          title="Integrações"
          content={
            <div>
              <IntegrationStatus provider={IntegrationProvider.SERASA} isMini={isMini} />
              <IntegrationStatus provider={IntegrationProvider.DATASUL} isMini={isMini} />
              <IntegrationStatus provider={IntegrationProvider.SAP} isMini={isMini} />
              <IntegrationStatus provider={IntegrationProvider.CUSTODY} isMini={isMini} />
            </div>
          }
        >
          <Container>
            <IntegrationLeds>
              <IntegrationStatusLed data-status={getLedStatus(serasa)} />
              <IntegrationStatusLed data-status={getLedStatus(datasul)} />
              <IntegrationStatusLed data-status={getLedStatus(sap)} />
              <IntegrationStatusLed data-status={getLedStatus(custody)} />
              {/* <IntegrationStatusLed data-status="loading" /> */}
            </IntegrationLeds>
            <p style={{ fontSize: isMini ? '10px' : '14px' }}>Integrações</p>
          </Container>
        </Popover>
      </div>
    );
  }),
);
