export enum CreditRequestModalityId {
  BARTER = '8d1d6061-bcfa-4e10-922c-2805122dc7d2',
  CASH = '64cf42f3-94df-4fe1-92c5-d3451bfd9088',
  CASH_BARTER = 'b0bbcd24-2064-4c04-af94-fa15683a4e1e',
}

export const MODIFYING_KEYS = [
  'Backspace',
  'Delete',
  'Enter',
  ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)), // A-Z
  ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)), // a-z
  ...Array.from({ length: 10 }, (_, i) => i.toString()), // 0-9
  ' ',
  ',',
  '.',
  '!',
  '?',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '-',
  '_',
  '+',
  '=',
  '[',
  ']',
  '{',
  '}',
  '|',
  '\\',
  ':',
  ';',
  '"',
  "'",
  '<',
  '>',
  '/',
  '`',
  '~',
];

export const PHYSICAL_PERSON_TYPE = 'App\\Model\\PhysicalPerson';
export const LEGAL_PERSON_TYPE = 'App\\Model\\LegalPerson';
export const DOCUMENT_TYPE_INSS = '21eacafa-5960-48db-bbb3-08e155ccdd9a';
export const DOCUMENT_TYPE_CONTRACT = '9d65f84f-c103-4dc7-bcb6-e5f01cd8ef9f';
export const DOCUMENT_TYPE_SINTEGRA = 'f43299c4-cf94-4ded-9ebe-a410bd779971';
export const DOCUMENT_DEPOSITARY = '9c9e52c0-3fe9-4e2b-b051-cafa115ebe22';
export const DOCUMENT_STATEMENT_OR_INJUNCTION = 'ca72558e-88ee-459a-bf07-dcc2c5e6dc19';
export const DOCUMENT_TYPE_CREDIT_ASSIGNMENT = 'acaa80a7-0d7c-42a6-8275-93f188a3961a';
export const DOCUMENT_MONSANTO = '49b220e5-f679-4791-ae8b-f36ff7475e7e';
export const DOCUMENT_TYPE_TAKEUP = '5ab3582e-5857-40e7-b0e9-50fa045df679';
export const DOCUMENT_TYPE_CONTRACT_ADVANCE_ADDITIVE = '838adbd1-54c6-442e-8a2a-33d8c8ac9575';
export const DOCUMENT_TYPE_DEPOSIT_CONTRACT = '36e83dc1-34b3-4d8e-b986-a925583c369e';
export const DOCUMENT_TYPE_OTHERS = 'a68e167d-c505-436c-8cde-22c7696bdf5c';

export const OPERATION_PREFIN_ID = '71171620-41b7-4c65-b994-3358b8c3f49e';
export const OPERATION_PREFIN_SUGAR_MILLS_ID = '2daebe88-f7e7-49d4-86aa-7b6df17b28e0';
export const OPERATION_PREPAY_ID = '704a3d8a-aa25-4141-9862-431f85600aab';
export const OPERATION_MTM_ID = '3f38c05a-b61f-442d-a374-89cd33aaa3de';
export const OPERATION_INTERN_MARKET_ID = '6b5d9a81-e3fc-443b-a0db-f979f9c25ecb';
export const OPERATION_FERTILIZER_SUPPLIERS_ID = '9476c002-29fd-4719-8190-7e57868f3f12';
export const OPERATION_DELIVERED_PRODUCT_ADVANCE_ID = '554d8377-fdf6-4204-8c28-4507ea00b7e5';
export const OPERATION_PREPAY_COTTON_ID = '63b2b901-75ae-4d5d-9f44-7f9bae3d663f';

export const OPERATION_SLUG_PREFIN = 'prefin';
export const OPERATION_SLUG_PREFIN_SUGAR_MILLS = 'prefin-sugar-mills';
export const OPERATION_SLUG_PREPAY = 'prepayment';
export const OPERATION_SLUG_MTM = 'mtm';
export const OPERATION_SLUG_INTERN_MARKET = 'intern_market';
export const OPERATION_SLUG_FERTILIZER_SUPPLIERS = 'fertilizer_suppliers';
export const OPERATION_SLUG_DELIVERED_PRODUCT_ADVANCE = 'delivered_product_advance';

export const DIVISION_SLUG_GEO = 'geo';
export const DIVISION_GEO_ID = '9f9f3cff-e3ef-4848-836d-900ea1e44257';
export const DIVISION_SLUG_SOFT = 'soft';
export const DIVISION_SOFT_ID = 'c68500c7-daa1-41e2-818c-c2f839436b88';

export const SUBDIVISION_SLUG_SOYBEAN = 'soybean';
export const SUBDIVISION_SOYBEAN_ID = '40fad3dc-ed53-41f0-83d6-2b081141fb07';
export const SUBDIVISION_SLUG_CORN = 'corn';
export const SUBDIVISION_CORN_ID = '4214e8ec-f27a-4c37-9530-7b070bd4baa1';
export const SUBDIVISION_SLUG_COTTON = 'cotton';
export const SUBDIVISION_COTTON_ID = '5863bbf8-8b2a-4f30-a81c-72b647edb746';
export const SUBDIVISION_SLUG_COTTON_SEEDS = 'cotton_seeds';
export const SUBDIVISION_COTTON_SEEDS_ID = '8bfbc8e9-14b8-40d3-9c1a-21c31168091f';
export const SUBDIVISION_SLUG_CRUSHING = 'crushing';
export const SUBDIVISION_CRUSHING_ID = 'a69c3def-d7c7-4917-932f-b28df69cfdf6';
export const SUBDIVISION_SLUG_WHEAT = 'wheat';
export const SUBDIVISION_WHEAT_ID = '4ba04312-f19c-487f-b7cf-0a49e0337f7d';
export const SUBDIVISION_SLUG_COFFEE = 'coffee';
export const SUBDIVISION_COFFEE_ID = '98d92579-db18-40af-aa26-1ce30285171a';
export const SUBDIVISION_SLUG_SUGAR_MILLS = 'sugar_mills';
export const SUBDIVISION_SUGAR_MILLS_ID = 'd8c1a416-193c-403a-88c2-ade61b50b01a';
export const SUBDIVISION_SLUG_SUGAR_TRADING = 'sugar_trading';
export const SUBDIVISION_SUGAR_TRADING_ID = '129cc1ab-0c87-4c60-b6ab-8d4eac0726a7';
export const SUBDIVISION_SLUG_ETHANOL = 'ethanol';
export const SUBDIVISION_ETHANOL_ID = '5b186b04-268c-418c-abc8-0ff573e2089c';
export const SUBDIVISION_SLUG_ENERGY = 'energy';
export const SUBDIVISION_ENERGY_ID = '48fd249c-1ac6-4fb8-8b41-4a4ffcedf472';
export const SUBDIVISION_SLUG_FERTILIZER = 'fertilizer';
export const SUBDIVISION_FERTILIZER_ID = '7d389cf1-91fc-4f8a-8a95-b9f5034aced2';

export const CURRENCY_BRL_ID = 'f313d5bc-f7ad-4da3-8910-c4e4b8407029';
export const CURRENCY_EUR_ID = '67f8054f-51e8-42a0-86ba-7a0953a11edc';
export const CURRENCY_USD_ID = '5ba5c7e1-5950-4368-b81a-e1e0a19c21a9';

export const WEIGHT_UNIT_SACK_ID = '6ce6a037-345c-4b3b-a54f-9154b5a869ad';
export const WEIGHT_UNIT_TON_ID = '1b8942da-6aee-45a6-bd5d-fdc5589371ae';
export const WEIGHT_UNIT_KG_ID = 'd9b65fd4-73df-4ce6-9705-9492b768e41f';

// Business Units
export const BUSINESS_FERTILIZER = 'a99b78f4-ca3d-45d3-a9dd-93b686abd16a';

/** Times */
export const TEAM_CREDIT = '1d1e3bc4-1298-4d54-adb2-4c4defb3e8fc';
export const TEAM_OPERATIONAL_RISK = '346fd352-7afe-4e2b-afdd-2e5830ecf282';
export const TEAM_SUSTAINABILITY = 'aa53d683-9d14-4456-a9cf-9876e0b4a2a0';
export const TEAM_DOCUMENTS = 'e23a3332-e27e-4c31-bdb6-8df3cca2eedf';
export const TEAM_CONTRACT = 'a2757a78-8999-4db7-b2bd-40974defbf57';
export const TEAM_APPROVATION = 'd9b23e18-0706-4c40-97a5-58d1bf96fde1';
export const TEAM_LEGAL = 'c4f9986e-9597-499c-aa41-2546582d07ac';
export const TEAM_PREFIN = '4778017d-f612-4f3b-87b4-29c627dca7f6';
export const TEAM_COMMERCIAL = 'ff1e155a-6bc1-4fb3-a934-89c451285dc9';

/** Contact types */
export const CONTACT_TYPE_MAIL = 'mail';
export const CONTACT_TYPE_PHONE = 'phone';

/**
 * Fases
 */
export const PHASE1_SOLICITATION = 'f8928367-3816-4464-a542-9e250d70ab02';
export const PHASE2_PRE_REGISTRATION = '7a7b8573-c77a-48bc-a9e8-7d2f2ef0c0e1';
export const PHASE3_POST_REGISTRATION = 'b9fd36d7-f7af-4d66-b523-88063a5f67d0';

/**
 * status fase solicitação
 */
export const TEAM_STATUS_NOT_SYNCED_ID = 'ea350b89-b983-46ca-82cf-76d2a469b977';
export const TEAM_STATUS_ADVANCE_PROGRAMMING_CONTRACTS_SOLICITATION_ID = '30c72e4c-1459-4653-91b6-b9b4f88cfa11';
export const TEAM_STATUS_COMMERCIAL_PENDING_ID = '13adc4b3-b050-4d53-b2b2-0f0ba58032b4';
export const TEAM_STATUS_IN_ANALYSIS_PREFIN_REQUEST = 'aef06ebc-29f0-4bc3-8187-c642009a212e';
export const TEAM_STATUS_ENABLED_LIMIT_CREDIT_SUGAR_MILLS_REQUEST = 'e0f0ca46-4f43-4a2a-8977-5d69a34da61a';
export const TEAM_STATUS_AWAITING_GR_APPROVAL_COMMERCIAL_ID = 'ba5e6814-d93d-4060-b014-a9cb031e9765';
export const TEAM_STATUS_ON_QUEUE_PREFIN_ID = '7d9e2554-3725-4593-8c07-48f46c3ede3f';
export const TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID = '0e67a884-aa53-4d08-9e20-6af29867d165';
export const TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID = '606f7723-4983-4e8d-afb5-333e4b37e0d6';
export const TEAM_STATUS_LIMIT_ENABLING_CREDIT_ID = 'ecbb8de7-e21b-4118-b163-8ab8dc094459';
export const TEAM_STATUS_LIMIT_ENABLING_CONTRACT_ID = '6106b7ea-0090-49cf-9f16-55b95c2d1520';
export const TEAM_STATUS_IN_ANALYSIS_CREDIT_ID = '8fcd8e49-0ed8-435c-8867-3125d16ba448';
export const TEAM_STATUS_SUSTAINABILITY_SOLICITATION_ID = 'f503a3c5-3b6f-4246-a4e7-b23f26006c4a';
export const TEAM_STATUS_COMMERCIAL_PENDING_OPERATIONAL_RISK_COMERCIAL_ID = '5bb5a0fa-a714-464b-b4d5-056d009dc94e';
export const TEAM_STATUS_COMMERCIAL_PENDING_CREDIT_COMERCIAL_ID = '6b3800d5-25d7-468c-b278-8dc36acdacea';
export const TEAM_STATUS_COMMERCIAL_PENDING_CONTRACT_COMERCIAL_ID = 'b800bef9-d7a3-494c-86a8-803c59cb4bba';
export const TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING = 'f6b49ef5-ab30-48a3-95db-6a788c0ab10d';
export const TEAM_STATUS_CONTRACT_ADVANCE_PROGAMMING_COMMERCIAL = '30c72e4c-1459-4653-91b6-b9b4f88cfa11';
export const TEAM_STATUS_ADVANCE_PROGRAMMING_EXECUTED_COMERCIAL = 'f8928367-3816-4464-a542-9e250d70ab02';
export const TEAM_STATUS_ADVANCE_PROGRAMMING_EXECUTED_COMERCIAL_SOLICITATION = 'dbbc2e9d-3442-4d1a-82d2-5cf866dbcc89';
export const TEAM_STATUS_TTP_SIGNED_IN_ANALYSIS_CONTRACT = '052ee930-2316-403a-9205-3967d60f029a';
export const TEAM_STATUS_LIMIT_ENABLING_CREDIT = '1972506d-cfe8-41b9-bc0f-caa1fe59e197';
export const TEAM_STATUS_APPROVED_CREDIT_FUTURE_HARVEST_CREDIT = '1c07568c-f90d-43d2-94c5-124c381d3123';
export const TEAM_STATUS_ENABLED_LIMIT_CONTRACT = '6106b7ea-0090-49cf-9f16-55b95c2d1520';
export const TEAM_STATUS_ADVANCE_APPROVING_GR_COMMERCIAL = 'b0320de7-e1a8-4f3c-9db4-60a35a3f310c';
export const TEAM_STATUS_COMMERCIAL_PENDING_APROVATION_REQUEST = '95e548c3-cb49-4657-914b-7cd8781f1d7d';
export const TEAM_STATUS_ADVANCE_PROGRAMMING_DATASUL_COMMERCIAL_REQUEST = 'b866cd5e-b7ef-41e4-8265-8bab273a782c';

export const TEAM_STATUS_NOT_SYNCED_SLUG = 'not_synced';
export const TEAM_STATUS_COMMERCIAL_PENDING_SLUG = 'commercial_pending_commercial';
export const TEAM_STATUS_AWAITING_GR_APPROVAL_COMMERCIAL_SLUG = 'awaiting_gr_approval_commercial';
export const TEAM_STATUS_ON_QUEUE_PREFIN_SLUG = 'on_queue_prefin';
export const TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG = 'credit_approved_contract_solicitation';
export const TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG = 'approved_credit_comercial_solicitation';
export const TEAM_STATUS_LIMIT_ENABLING_CREDIT_SLUG = 'limit_enabling_credit';
export const TEAM_STATUS_LIMIT_ENABLING_CONTRACT_SLUG = 'limit_enabled_contract_solicitation';
export const TEAM_STATUS_IN_ANALYSIS_CREDIT_SLUG = 'in_analysis_credit';

/** status fase pré-registro */
export const TEAM_STATUS_ON_QUEUE_PREFIN_PRE_REGISTRATION = '626fe382-1bd6-417b-8d8e-a27aaac1dcbe';
export const TEAM_STATUS_LIMIT_ENABLING_CREDIT_PRE_REGISTRATION = 'b4381bfa-ec39-4ece-85db-a2e7b772a0c3';
export const TEAM_STATUS_LIMIT_ENABLED_CREDIT_PRE_REGISTRATION = 'a21e8ce7-ad88-4682-8aad-f29f0f92ae1f';

/** status fase pós-registro */
export const TEAM_STATUS_COMMERCIAL_COMMERCIAL_PENDING_POST_REGISTRATION = '3be62e51-4bd9-4515-a2f8-923c1ad57542';
export const TEAM_STATUS_COMMERCIAL_CANCELED_POST_REGISTRATION = 'cebc63df-5303-4da8-9c49-db830362b9cb';
export const TEAM_STATUS_CREDIT_ON_QUEUE_POST_REGISTRATION = '9c60cb6e-1702-4f34-88cd-944a53867a95';
export const TEAM_STATUS_CREDIT_IN_ANALISYS_POST_REGISTRATION = '7b6d33b9-85da-4dc0-b8c4-5cf833405ccb';
export const TEAM_STATUS_CREDIT_LIMIT_ENABLING_POST_REGISTRATION = 'e963ffb8-0cd4-44e8-8928-84e969ae7627';
export const TEAM_STATUS_CREDIT_LIMIT_ENABLED_POST_REGISTRATION = '167af3bb-94db-4475-90dc-66a8d53c49d4';
export const TEAM_STATUS_DOCUMENT_PENDING_POST_REGISTRATION = '5705bf2b-0849-43a2-9b62-6f2a13de3f47';
export const TEAM_STATUS_DOCUMENT_IN_ANALISYS_POST_REGISTRATION = '16af98d7-803f-4cbc-a94c-d970d2a28fbd';
export const TEAM_STATUS_LEGAL_ON_QUEUE_POST_REGISTRATION = 'b60de501-db92-4ab6-92cc-1dba07c4c355';
export const TEAM_STATUS_LEGAL_IN_ANALISYS_POST_REGISTRATION = '6ff972c7-0a7e-4949-8f7d-a016f757c231';
export const TEAM_STATUS_PREFIN_REGISTERED_DOCUMENTS_POST_REGISTRATION = '80d8d36d-d854-48b0-9d4b-091890af66db';
export const TEAM_STATUS_PREFIN_IN_ANALISYS_POST_REGISTRATION = 'edeb059a-e2a3-41d9-818a-9fb5e50f2cf3';
export const TEAM_STATUS_PREFIN_COMMERCIAL_PENDING_RETURN_POST_REGISTRATION = 'dac91cc5-41a0-413f-8118-2b045bded0e2';
export const TEAM_STATUS_PREFIN_BACKOFFICE_RETURN_POST_REGISTRATION = '2fb0ed2a-7e2f-46f0-8674-a4b7355000ed';
export const TEAM_STATUS_PREFIN_RETURN_SUPPORT_AREA_POST_REGISTRATION = '9f4dc422-2700-4082-9c5c-2818cb32dd9d';

/** Team-Status Fase 3 - Pós-Registro */
export const PHASE3_POST_REGISTRATION_TEAM_STATUS = [
  TEAM_STATUS_COMMERCIAL_COMMERCIAL_PENDING_POST_REGISTRATION,
  TEAM_STATUS_COMMERCIAL_CANCELED_POST_REGISTRATION,
  TEAM_STATUS_CREDIT_ON_QUEUE_POST_REGISTRATION,
  TEAM_STATUS_CREDIT_IN_ANALISYS_POST_REGISTRATION,
  TEAM_STATUS_CREDIT_LIMIT_ENABLING_POST_REGISTRATION,
  TEAM_STATUS_CREDIT_LIMIT_ENABLED_POST_REGISTRATION,
  TEAM_STATUS_DOCUMENT_PENDING_POST_REGISTRATION,
  TEAM_STATUS_DOCUMENT_IN_ANALISYS_POST_REGISTRATION,
  TEAM_STATUS_LEGAL_ON_QUEUE_POST_REGISTRATION,
  TEAM_STATUS_LEGAL_IN_ANALISYS_POST_REGISTRATION,
  TEAM_STATUS_PREFIN_REGISTERED_DOCUMENTS_POST_REGISTRATION,
  TEAM_STATUS_PREFIN_IN_ANALISYS_POST_REGISTRATION,
  TEAM_STATUS_PREFIN_COMMERCIAL_PENDING_RETURN_POST_REGISTRATION,
  TEAM_STATUS_PREFIN_BACKOFFICE_RETURN_POST_REGISTRATION,
  TEAM_STATUS_PREFIN_RETURN_SUPPORT_AREA_POST_REGISTRATION,
];

/** Survey Approval Status */
export const SURVEY_APPROVAL_STATUS_DRAFT = 'b8dc2490-12a2-4d36-b505-a7852d5c3baf';
export const SURVEY_APPROVAL_STATUS_APPROVED = 'f2eb9264-2fdb-4374-b694-ef66a167f7d3';

/** Partial Limit Status */
export const PARTIAL_LIMIT_STATUS_DISAPPROVED = 'fd7a774a-03e3-40b1-84da-ded17d7d32fc';

/* Contract Type */
export const CONTRACT_TYPE_FIELD_TYPE_COMBO = '3807005f-de0b-49d7-9d8c-109050918048';
export const CONTRACT_TYPE_FIELD_TYPE_DATE = '969e5ce3-55bf-482d-a7a6-0c228d982dbb';
export const CONTRACT_TYPE_FIELD_TYPE_DATETIME = '50d2a11e-9a83-43a3-a0a0-1070ab8e06df';
export const CONTRACT_TYPE_FIELD_TYPE_NUMBER = 'e91eaa94-3c9f-462b-bd97-9e2674803847';
export const CONTRACT_TYPE_FIELD_TYPE_STRING = 'f6bb198d-fce7-4dba-a7d7-b8b55d555bf7';
export const CONTRACT_TYPE_FIELD_TYPE_TABLE = 'c6bc7a6d-a0fe-4ae3-9d39-f649e71082ef';
export const CONTRACT_TYPE_FIELD_TYPE_TEXT = 'd41b61aa-95a0-46a3-82fd-645925668ecf';
export const CONTRACT_TYPE_FIELD_TYPE_TIME = 'ff25d118-fdd3-4a3c-96b4-79c5d35efe39';
export const CONTRACT_TYPE_FIELD_TYPE_CPF = 'ccb2f0ad-f099-4564-ab82-f637b65b4f15';
export const CONTRACT_TYPE_FIELD_TYPE_CNPJ = '34ab90a4-9afb-46ad-a633-f69b15b22217';
export const CONTRACT_TYPE_FIELD_TYPE_DECIMAL = 'f41fe0f3-f76a-47d5-aeef-96480330653c';
export const CONTRACT_TYPE_FIELD_TYPE_DECIMAL2 = '984c7311-4258-4df6-9158-075c923624d5';
export const CONTRACT_TYPE_FIELD_TYPE_DECIMAL3 = '878dbf17-6a90-42ea-b8dc-6be0ce7f480c';
export const CONTRACT_TYPE_FIELD_TYPE_DECIMAL4 = 'fbbadd09-5879-4bcc-8e01-0ced318a6614';
export const CONTRACT_TYPE_FIELD_TYPE_MONEY = 'f41fe0f3-f76a-47d5-aeef-96480330653c';

// Contract Status
export const CONTRACT_STATUS_VALIDATE_EMISSION = '2e329a64-2c38-4726-8bd2-cce2903e9488';
export const CONTRACT_STATUS_VALIDATE_EMISSION_ORDER = 4;
export const CONTRACT_STATUS_APPROVED_EMISSION = 'c6436076-82e6-4dce-93ee-9cbad3d3bef1';
export const CONTRACT_STATUS_APPROVED_EMISSION_ORDER = 5;
export const CONTRACT_STATUS_VALIDATE_SIGNATURE = 'd22de461-d5c4-4730-9f54-16c77b616e96';
export const CONTRACT_STATUS_VALIDATE_SIGNATURE_ORDER = 11;
export const CONTRACT_STATUS_APPROVED_SIGNATURE = '4c83a2ad-f137-4535-a440-4ccbc87db142';
export const CONTRACT_STATUS_APPROVED_SIGNATURE_ORDER = 12;
export const CONTRACT_STATUS_UPLOADED_SIGNED_CONTRACT = '80522ca1-1080-4f01-bfc3-786a4fb81f96';
export const CONTRACT_STATUS_WAITING = 'ecb29bdc-7463-4168-8288-3dbe50f66167';
export const CONTRACT_STATUS_WAITING_ORDER = 7;
export const CONTRACT_STATUS_SENT_SIGNATURE = '80522ca1-1080-4f01-bfc3-786a4fb81f96';
export const CONTRACT_STATUS_SENT_SIGNATURE_ORDER = 8;
export const CONTRACT_STATUS_FINISHED = '4c83a2ad-f137-4535-a440-4ccbc87db142';
export const CONTRACT_STATUS_STORED = '3f1ea808-2b71-43ac-bb18-c30f74f95f0a';
export const CONTRACT_STATUS_STORED_ORDER = 14;
export const CONTRACT_STATUS_PARTIALLY_SIGNED = '63d5ca24-e881-4a8a-a6c9-8d62847dabd8';
export const CONTRACT_STATUS_PARTIALLY_SIGNED_ORDER = 8;
export const CONTRACT_STATUS_REISSUE_DRAFT = '025147a2-600d-410c-b129-e835977a9f80';
export const CONTRACT_STATUS_REISSUE_DRAFT_ORDER = 2;
export const CONTRACT_STATUS_REQUEST_NEW_SIGNATURES = 'e21f2e68-214d-432f-91ea-ff3c1a2d8528';
export const CONTRACT_STATUS_REQUEST_NEW_SIGNATURES_ORDER = 9;
export const CONTRACT_STATUS_FULLY_SIGNED = 'f32b1a9d-9d3d-43d2-978a-1a14e5ad7b00';
export const CONTRACT_STATUS_FULLY_SIGNED_ORDER = 10;
export const CONTRACT_STATUS_COMPLETED_DRAFT = '17440652-0882-4dae-a6b8-fe76842fdad1';
export const CONTRACT_STATUS_COMPLETED_DRAFT_ORDER = 3;
export const CONTRACT_STATUS_CREATED = '7dedf1b5-691c-4921-baf2-80500c41dbfd';
export const CONTRACT_STATUS_CREATED_ORDER = 1;

// Contract Signature Parts
export const CONTRACT_SIGNATURE_PART_FOLLOWER = '03e32e75-ac8b-417d-bccf-db886f2cc985';
export const CONTRACT_SIGNATURE_PART_BUYER = 'fe6e684e-6bb5-42c2-99e0-0a5c9cebd6a9';

// Contract Signature Status
export const CONTRACT_SIGNATURE_STATUS_AUTORESPONDED = 'd6029abb-d9b0-4589-9b4c-8cabd750eb93';
export const CONTRACT_SIGNATURE_STATUS_COMPLETED = '40ad251f-c86d-4298-a690-c869bf1cb6e8';
export const CONTRACT_SIGNATURE_STATUS_CREATED = '61b09fa1-9b9b-4ee7-b9a0-776d6a6e25ee';
export const CONTRACT_SIGNATURE_STATUS_DECLINED = '7d3e719e-820e-4864-9a18-881c30f49eb9';
export const CONTRACT_SIGNATURE_STATUS_DELIVERED = '21666c6a-43d8-4e1e-8426-6d49d52ba797';
export const CONTRACT_SIGNATURE_STATUS_SENT = '554afa49-d782-4441-8322-8974142441e5';

// Contract Signature Types
export const CONTRACT_SIGNATURE_DSELETRONIC = 'a7d95d8a-db9c-42f1-aad8-f3ddabac2d70';
export const CONTRACT_SIGNATURE_ICPBRASIL = 'c363e348-68d2-49e9-be02-21e8d74f145e';

// Contract Purchase Types
export const CONTRACT_PURCHASE_TYPE_FIXO_EXT = 'Compra Fixa';
export const CONTRACT_PURCHASE_TYPE_FIXAR_EXT = 'Compra A Fixar';
export const CONTRACT_PURCHASE_TYPE_FIXAR = 'eca2b90a-24be-406d-b60c-ceb5b8089ab1';
export const CONTRACT_PURCHASE_TYPE_FIXO = '51563d3d-0cb6-42b0-bc74-0d99de79b200';
export const CONTRACT_PURCHASE_TYPE_DAP = 'aeaf57ba-8214-451e-b29e-39a36e28bd9a';

/* Reason */
export const REASON_EXPOSURE = '3011c8d9-b686-473b-94b0-ac0e0e1542b3';

/** home page */
export const HOME_PAGE_URL = '/home';
export const LOGIN_PAGE_URL = '/login';
export const TFA_PAGE_URL = '/two-factor-auth';
export const EXPIRED_PAGE_URL = '/expired';

export const AUTH_2FA_ENABLED = import.meta.env.VITE_AUTH_2FA_ENABLED ?? true;
export const AUTH_PASSWORD_EXPIRES_ENABLED = import.meta.env.VITE_AUTH_PASSWORD_EXPIRES_ENABLED ?? true;

/** Sustainability Report Types */

export const SUSTAINABILITY_DECISION_IN_ANALYSIS = '';
export const SUSTAINABILITY_DECISION_IN_ANALYSIS_COLOR = 'black';
export const SUSTAINABILITY_DECISION_APPROVED = '2f5b93b7-484e-4a44-b7f6-61bba80c9d4f';
export const SUSTAINABILITY_DECISION_APPROVED_COLOR = 'green';
export const SUSTAINABILITY_DECISION_DISAPPROVED = '2f9d61bb-edb2-4339-8322-b59e56a4ba16';
export const SUSTAINABILITY_DECISION_DISAPPROVED_COLOR = 'red';
export const SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS = '42859c80-b402-491c-b341-3a2dac00cc55';
export const SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS_COLOR = 'orange';

export const SUSTAINABILITY_REGISTRATION_STATUS_IN_ANALYSIS = 'f0d1b689-2e50-4e75-bcc5-6c9bf153c5b1';
export const SUSTAINABILITY_REGISTRATION_STATUS_IN_ANALYSIS_COLOR = 'black';
export const SUSTAINABILITY_REGISTRATION_STATUS_APPROVED = '8cf10954-52fb-4fa0-acc2-c726dc147746';
export const SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_COLOR = 'green';
export const SUSTAINABILITY_REGISTRATION_STATUS_DISAPPROVED = 'd7eadbea-8d79-49cc-a576-b3dfd90d1516';
export const SUSTAINABILITY_REGISTRATION_STATUS_DISAPPROVED_COLOR = 'red';
export const SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_WITH_RESERVATIONS = '4856b289-fe56-47ef-81ec-9aaced113fef';
export const SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_WITH_RESERVATIONS_COLOR = 'orange';

export const SUSTAINABILITY_STATUS_BLOQUEIO = '3627478e-091f-4ce9-8d60-54818dcaa95d';
export const SUSTAINABILITY_STATUS_PROSPECCAO = 'd3bb44b1-c95e-43d2-ae33-e64c51e92306';
export const SUSTAINABILITY_APROVADO_RESSALVAS = '42859c80-b402-491c-b341-3a2dac00cc55';
export const SUSTAINABILITY_STATUS_FINALIZADO = '7262a194-e5c2-432b-9e7f-81d7b200be8e';
export const SUSTAINABILITY_STATUS_CANCELLED = '475ed483-dfb9-4c81-b540-5abd5107f212';
export const SUSTAINABILITY_STATUS_REPROVED = 'c9464bee-ff95-4fb6-b17a-d13256da06c5';
export const SUSTAINABILITY_STATUS_PREFIN = '8957c4da-6168-4b70-9447-68043d65c044';
export const SUSTAINABILITY_STATUS_REQUESTED = '982ea921-52e6-440d-9000-a9b6093a8cc7';
export const SUSTAINABILITY_STATUS_WAITING_UNBLOCK = '7581db99-ccb6-4429-a545-b11316f4c33e';
export const SUSTAINABILITY_STATUS_UNBLOCKED = '2142c2db-a51c-457d-bffe-2718044e7d13';

export const SUSTAINABILITY_NEXT_STATUS_REQUESTED = '982ea921-52e6-440d-9000-a9b6093a8cc7';
export const SUSTAINABILITY_NEXT_STATUS_COMMERCIAL_PENDING = '38aa34d1-a975-4d0b-bb2a-22499dc062aa';
export const SUSTAINABILITY_NEXT_STATUS_CANCELLED = '475ed483-dfb9-4c81-b540-5abd5107f212';
export const SUSTAINABILITY_NEXT_STATUS_PREFIN_QUEUE = '51303c7f-86ed-4563-baff-8c77f0edbd3d';
export const SUSTAINABILITY_NEXT_STATUS_LEGAL_QUEUE = '48a7c5f4-4b31-461c-b6ca-e67cf907e28c';
export const SUSTAINABILITY_NEXT_STATUS_FINISHED = '7262a194-e5c2-432b-9e7f-81d7b200be8e';
export const SUSTAINABILITY_NEXT_WAITING_UNBLOCK = '7581db99-ccb6-4429-a545-b11316f4c33e';
export const SUSTAINABILITY_NEXT_UNBLOCKED = '2142c2db-a51c-457d-bffe-2718044e7d13';

/** Serasa Status Types */

export const SERASA_STATUS_ANALYSIS = 'e77f9fec-f562-4217-837a-e073f41a76ba';
export const SERASA_STATUS_WITHOUT_RESTRICTION = 'e543e33c-6d60-4fe1-8909-a2b4a0828d50';
export const SERASA_STATUS_WITH_RESTRICTION = 'e3839b54-9628-427f-8a33-5524e6f1a98f';

export const CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE = [
  TEAM_STATUS_COMMERCIAL_PENDING_ID,
  // TEAM_STATUS_IN_ANALYSIS_CREDIT_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_OPERATIONAL_RISK_COMERCIAL_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_CREDIT_COMERCIAL_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_CONTRACT_COMERCIAL_ID,
];

export const CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY = [
  TEAM_STATUS_COMMERCIAL_PENDING_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_OPERATIONAL_RISK_COMERCIAL_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_CREDIT_COMERCIAL_ID,
  TEAM_STATUS_COMMERCIAL_PENDING_CONTRACT_COMERCIAL_ID,
];

export const CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY_COTTON = [TEAM_STATUS_COMMERCIAL_PENDING_ID];

export const CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS = [TEAM_STATUS_COMMERCIAL_PENDING_ID];

export const CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_CUSTODY = [
  TEAM_STATUS_COMMERCIAL_PENDING_OPERATIONAL_RISK_COMERCIAL_ID,
];

// file types
export const DOCUMENT_TYPES = ['docx', 'pdf', 'ppt', 'pptx', 'xls', 'xls', 'xlsx'];
export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'svg'];
export const TEXT_TYPES = ['csv'];

// sustainability registration status
export const SUSTAINABILITY_REGISTRATION_IN_ANALYSIS = 'f0d1b689-2e50-4e75-bcc5-6c9bf153c5b1';

// survey condition
export const SURVEY_CROP_CONDITION_VERY_GOOD = '8cfd5e4b-2fc3-416c-931e-bba4b6e93305';
export const SURVEY_CROP_CONDITION_GOOD = '5d10b0bd-58ec-4191-b574-f5b8c0ac5c9f';
export const SURVEY_CROP_CONDITION_FAIR = '7d4d305c-241b-41d7-b7cb-686cd3cb60b1';
export const SURVEY_CROP_CONDITION_BAD = '3f2477a1-445c-409c-9fce-38a48ecb338d';
export const SURVEY_CROP_CONDITION_VERY_BAD = 'fefa7a86-0b44-488f-9ef3-008fb69db10a';

// survey types

export const PRESENTIAL_SURVEY = '9275c41a-0090-456b-8650-c1b2458f5c49';
export const REMOTE_SURVEY = '4c55bd44-2d2b-4193-b720-560ecc239202';

export const REGION_GO = '1b647159-897f-45fd-94c3-0fc89c4ad890';
export const STATE_GO = 'b40a0a12-d82f-4848-9d6f-ba5fdf8e9189';
export const STATE_MT = '7c7a8723-5d3d-4ca8-99d1-5857df07f628';

export const REGION_COTTON = '7817fac7-a90c-4f54-a581-0c1c18455bf7';

export const SUBSIDIARY_COTTON = '275f6215-4791-412f-9c34-878e69e781f6';

export const FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN = 'c2bf4060-bf35-405f-bc99-15d3d89479a8';
export const FORMALIZATION_GUARANTEES_SUBSCRIBER_COPIED = '59559669-1ad2-4533-8c73-b81453211f26';

export const SAP_OPERATIONS = [
  OPERATION_MTM_ID,
  OPERATION_SLUG_MTM,
  OPERATION_PREFIN_ID,
  OPERATION_SLUG_PREFIN,
  OPERATION_PREPAY_ID,
  OPERATION_SLUG_PREPAY,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  OPERATION_SLUG_FERTILIZER_SUPPLIERS,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_SLUG_INTERN_MARKET,
  OPERATION_SLUG_PREFIN_SUGAR_MILLS,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
];

export const GuaranteeTypeEnum = {
  DEFAULT: 'c1fc838d-e0ae-4602-bf7c-656d477822c5',
  WITH_EXCEPTION: '33b576ca-9fda-44e3-ad73-62c535357958',
};

export const GUARANTEE_TYPE_LABEL = {
  'c1fc838d-e0ae-4602-bf7c-656d477822c5': 'register_cpr',
  '33b576ca-9fda-44e3-ad73-62c535357958': 'no_register_cpr',
} as { [key: string]: string };

export const JUSTIFICATION_OPTIONS = {
  OTHER: 'ddbcad12-d44d-413d-8d47-29864907a644',
  APPROVED: '77da7773-2821-4055-a228-015f3972cd9c',
};

export enum GuaranteeStatus {
  APPROVED = 'eeea5026-9ad0-4ace-8b8a-e9d75e99de70', // Aprovado CREDITO
  REPROVED = 'fd7a774a-03e3-40b1-84da-ded17d7d32fc', // Reprovado CREDITO
  VALIDATED = 'e8878587-af1b-4b51-9baa-faa00775058f', // Validado PREFIN
  REJECTED = '7b173750-0fd1-4252-8915-ef73860117f7', // Rejeitado COMERCIAL
}

export enum Teams {
  OPERATIONAL_RISK = '346fd352-7afe-4e2b-afdd-2e5830ecf282',
  APPROVAL = 'd9b23e18-0706-4c40-97a5-58d1bf96fde1',
  PREFIN = '4778017d-f612-4f3b-87b4-29c627dca7f6',
  COMMERCIAL = 'ff1e155a-6bc1-4fb3-a934-89c451285dc9',
  CONTRATO = 'a2757a78-8999-4db7-b2bd-40974defbf57',
  CREDIT = '1d1e3bc4-1298-4d54-adb2-4c4defb3e8fc',
  JURIDIC = 'c4f9986e-9597-499c-aa41-2546582d07ac',
  SUSTAINABILITY = 'aa53d683-9d14-4456-a9cf-9876e0b4a2a0',
  DOCUMENTS = 'e23a3332-e27e-4c31-bdb6-8df3cca2eedf',
}

export enum ImportGroupEnum {
  CREDIT_ADVANCE_LIMIT = 'b5996464-71a0-4967-b93e-09458acda52a',
}

export enum SapStatusAdvanceProgEnum {
  RETURNED = 'ESTORNADO',
  APPROVED = 'APROVADO',
  REPROVED = 'REJEITADO',
  PAID = 'PAGO',
}

export enum ContractOperationType {
  BUY = '0f0df6ab-162e-4cf7-a4ba-92159ad5d9d8',
  SELL = '10e4b71c-4fc3-4765-abc1-5f18e81d6b58',
}

export const MINUTE = 1000 * 60;

export enum RecipientType {
  SUBSCRIBER = 'c2bf4060-bf35-405f-bc99-15d3d89479a8',
  COPIED = '59559669-1ad2-4533-8c73-b81453211f26',
}

export enum RegisteredPersonDraftStage {
  DRAFT = '56575a70-0fdf-4e72-8445-eede3550524e',
  PENDING = '00a1738e-2be9-46e6-b4cd-92030875cd50',
  REGISTERED = 'fc254ed2-c458-45b3-908b-ad44fb1e1820',
  CANCELED = '24971cde-43b5-4460-a254-cbd9235759c5',
}

export const TEAMS_CREDIT = ['prefin', 'commercial', 'contract', 'credit', 'legal'];
export const MONITORING_CPR_SITUATION_LEASED = '62c6115f-295d-4ec1-89cb-171df454d2c2';
