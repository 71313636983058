import React, { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from '@/components/ShouldRender';
import { DataTable } from '@/components/ui/data-table';
import { Form } from '@/components/ui/form';
import { FormItem } from '@/components/ui/form-item';
import { IconTooltip } from '@/components/ui/icon-tooltip';
import { Input } from '@/components/ui/input';
import Loading from '@/components/ui/loading';
import { PageTitle } from '@/components/ui/page-title';
import { Switch } from '@/components/ui/switch';
import { ToastProps } from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/hooks/auth';
import { InfoIcon } from 'lucide-react';

import { Button, ModalStatus } from '../../../components';
import {
  NotificationTreeResponse,
  useGetNotificationConfigurationTreeQuery,
} from './api/use-get-notification-configuration-tree.query';
import {
  DTOGetNotificationConfiguration,
  useGetNotificationConfigurationQuery,
} from './api/use-get-notification-configuration.query';
import { useUpdateNotificationConfig } from './api/use-update-notification-config.mutation';
import { SFormButtons } from './styles';
import { NotificationRowData, userNotificationConfigFormConfig } from './user-notification-config.form.config';

interface Props {
  userId: string;
}

export interface NotificationFormValues {
  messages: {
    [key: string]: {
      id: string;
      via_email: boolean;
      via_broadcast: boolean;
      via_teams: boolean;
    };
  };
  stop_email_notifications: boolean;
  stop_teams_notifications: boolean;
  stop_broadcast_notifications: boolean;
}

export const NotificationConfigTab = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { updateUserData } = useAuth();
  const { toast } = useToast();

  const [search, setSearch] = useState('');

  const form = useForm<NotificationFormValues>({
    defaultValues: {
      messages: {},
      stop_email_notifications: false,
      stop_teams_notifications: false,
      stop_broadcast_notifications: false,
    },
  });

  const initializeFormUserConfig = (values: DTOGetNotificationConfiguration) => {
    const { user_message } = values;
    form.reset({
      ...values,
      messages: Object.values(form.getValues('messages'))
        .map(message => {
          const notification = user_message.find(notification => notification.message_id === message.id);
          return {
            ...message,
            via_email: notification?.via_email ?? false,
            via_broadcast: notification?.via_broadcast ?? false,
            via_teams: notification?.via_teams ?? false,
          };
        })
        .reduce(
          (acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          },
          {} as NotificationFormValues['messages'],
        ),
    });
  };

  const initializeFormTreeNotifications = (values: NotificationTreeResponse) => {
    const formValues = form.getValues('messages');
    const newValues = {} as NotificationFormValues['messages'];
    values.forEach(item => {
      newValues[item.id] = {
        id: item.id,
        via_email: formValues[item.id]?.via_email ?? false,
        via_broadcast: formValues[item.id]?.via_broadcast ?? false,
        via_teams: formValues[item.id]?.via_teams ?? false,
      };
    });
    form.reset({
      ...form.getValues(),
      messages: newValues,
    });
  };

  const updateNotificationConfigurationMutation = useUpdateNotificationConfig();
  const getNotificationConfigurationTree = useGetNotificationConfigurationTreeQuery({
    onSuccess: data => initializeFormTreeNotifications(data),
  });
  const getNotificationConfigurationQuery = useGetNotificationConfigurationQuery({
    userId,
    onSuccess: data => {
      initializeFormUserConfig(data);
    },
    enabled: !getNotificationConfigurationTree.isFetching,
  });

  const notifications = getNotificationConfigurationTree.data || [];

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleEdit = async () => {
    const values = form.getValues();
    const filteredMessages = Object.values(values.messages)
      .filter(message => message.via_email || message.via_broadcast || message.via_teams)
      .map(message => ({
        id: message.id,
        attributes: {
          via_email: message.via_email,
          via_broadcast: message.via_broadcast,
          via_teams: message.via_teams,
        },
      }));

    const response = await updateNotificationConfigurationMutation.mutateAsync({
      userId,
      messages: filteredMessages,
      stop_email_notifications: values.stop_email_notifications ?? false,
      stop_teams_notifications: values.stop_teams_notifications ?? false,
      stop_broadcast_notifications: values.stop_broadcast_notifications ?? false,
    });

    if (response) {
      handleSuccess(t('user.notification.success'));
      updateUserData();
      setSearch('');
    }
  };

  const filteredNotifications = notifications.filter(notification =>
    notification?.name?.['pt-br']?.toLowerCase().includes(search?.toLowerCase()),
  );

  const { columns, expanded, groupedNotifications } = userNotificationConfigFormConfig({
    notifications: filteredNotifications,
    messages: form.watch('messages'),
    form,
  });

  const tableData = useMemo(() => {
    if (!getNotificationConfigurationTree.data) return [];
    const result: NotificationRowData[] = [];
    Object.entries(groupedNotifications).forEach(([type, filteredNotifications]) => {
      result.push({
        id: `group-${type}`,
        name: { 'pt-br': '', en: '' },
        message_type_id: '',
        message_type: { id: '', name: '' },
        isGroupHeader: true,
        groupType: type,
      });

      if (!expanded[type]) {
        filteredNotifications.forEach(notification => {
          result.push({
            ...notification,
            isGroupHeader: false,
            id: notification.id,
          });
        });
      }
    });

    return result;
  }, [notifications, expanded, search, groupedNotifications, getNotificationConfigurationTree.data]);

  return (
    <Form form={form} onSubmit={handleEdit} className="flex h-full flex-col overflow-hidden bg-white p-4">
      <PageTitle className="mb-4">{t('config-notifications')}</PageTitle>
      <div className="flex h-full w-full flex-col gap-6 overflow-hidden">
        <div className="flex items-center justify-between gap-8">
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('search-notification')}
            className="max-w-[400px]"
          />
        </div>

        <div
          className={`grid transition-all duration-300 ease-in-out ${
            getNotificationConfigurationTree.isFetching || getNotificationConfigurationQuery.isFetching
              ? 'grid-rows-[1fr] opacity-100'
              : 'grid-rows-[0fr] opacity-0'
          }`}
        >
          <div className="overflow-hidden">
            <div className="flex items-center justify-center gap-2 py-2">{t('searching-your-notifications')}</div>
          </div>
        </div>

        <div className="h-full w-full overflow-hidden overflow-x-auto">
          <DataTable
            columns={columns}
            data={tableData}
            enableSorting={false}
            withPagination={false}
            isLoading={getNotificationConfigurationTree.isLoading || getNotificationConfigurationQuery.isLoading}
            isFetching={getNotificationConfigurationTree.isFetching || getNotificationConfigurationQuery.isFetching}
            fixedHeader
          />
        </div>

        <Loading isLoading={getNotificationConfigurationTree.isLoading || getNotificationConfigurationQuery.isLoading}>
          <div className="flex items-center gap-8">
            <FormItem
              name="stop_email_notifications"
              label={
                <span className="flex items-center gap-2">
                  {t('stop-send-email')}
                  <IconTooltip title={t('stop-send-email-tooltip')}>
                    <InfoIcon className="h-4 w-4" />
                  </IconTooltip>
                </span>
              }
              className="flex items-center gap-2"
            >
              <Switch
                checked={form.watch('stop_email_notifications')}
                onCheckedChange={checked => form.setValue('stop_email_notifications', checked, { shouldDirty: true })}
              />
            </FormItem>

            <FormItem
              name="stop_teams_notifications"
              label={
                <span className="flex items-center gap-2">
                  {t('stop-send-to-teams')}
                  <IconTooltip title={t('stop-send-to-teams-tooltip')}>
                    <InfoIcon className="h-4 w-4" />
                  </IconTooltip>
                </span>
              }
              className="flex items-center gap-2"
            >
              <Switch
                checked={form.watch('stop_teams_notifications')}
                onCheckedChange={checked => form.setValue('stop_teams_notifications', checked, { shouldDirty: true })}
              />
            </FormItem>

            <FormItem
              name="stop_broadcast_notifications"
              label={
                <span className="flex items-center gap-2">
                  {t('stop-send-on-site')}
                  <IconTooltip title={t('stop-send-on-site-tooltip')}>
                    <InfoIcon className="h-4 w-4" />
                  </IconTooltip>
                </span>
              }
              className="flex items-center gap-2"
            >
              <Switch
                checked={form.watch('stop_broadcast_notifications')}
                onCheckedChange={checked =>
                  form.setValue('stop_broadcast_notifications', checked, { shouldDirty: true })
                }
              />
            </FormItem>
          </div>
        </Loading>

        <SFormButtons>
          <Button
            status="secondary"
            htmlType="reset"
            onClick={() => form.reset()}
            disabled={false}
            loading={getNotificationConfigurationTree.isLoading || updateNotificationConfigurationMutation.isPending}
          >
            {t('pages.admin.user.buttonCancel')}
          </Button>
          <Button
            status="primary"
            htmlType="submit"
            disabled={false}
            loading={updateNotificationConfigurationMutation.isPending || getNotificationConfigurationTree.isLoading}
          >
            {t('pages.admin.user.buttonEdit')}
          </Button>
        </SFormButtons>
      </div>
    </Form>
  );
};
