import { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSearchDollar } from 'react-icons/fa';

import { ShouldRender } from '@/components/ShouldRender';
import { Button } from '@/components/ui/button';
import { DatePicker } from '@/components/ui/date-picker';
import { Form } from '@/components/ui/form';
import { FormItem } from '@/components/ui/form-item';
import { Input, InputMoney } from '@/components/ui/input';
import Loading from '@/components/ui/loading';
import { MentionInput } from '@/components/ui/mention-input';
import { Upload } from '@/components/ui/upload';
import {
  OPERATION_INTERN_MARKET_ID,
  OPERATION_MTM_ID,
  OPERATION_PREFIN_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
} from '@/configs/constants';
import { MentionProvider } from '@/context/mention-input.provider';
import { usePrefinModalityRules } from '@/pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import moment from 'moment';

import { ChangeCreditRequestStatusDataProps, CreditStatusChangeFormValues } from './creditStatus';
import { usePtax } from './fetch/usePtax';

type CreditStatusFormProps = {
  changeCreditRequestStatusData: ChangeCreditRequestStatusDataProps;
  fieldAmount: string[];
  form: UseFormReturn<CreditStatusChangeFormValues>;
  isFetchingSubsidiarySapCode: boolean;
  shouldShowLimitInputs: boolean;
  shouldBlockApprovedValue: boolean;
  isInvalidTargetStatusAttachmentRule: boolean;
  setMentionedUserIds: (mentionedUserIds: string[]) => void;
};

export const CreditStatusForm = ({
  changeCreditRequestStatusData,
  fieldAmount,
  form,
  isFetchingSubsidiarySapCode,
  shouldShowLimitInputs,
  shouldBlockApprovedValue,
  isInvalidTargetStatusAttachmentRule,
  setMentionedUserIds,
}: CreditStatusFormProps) => {
  const { t } = useTranslation();
  const { getLastPtax, isFetching: isFetchingPtax } = usePtax();
  const { getShouldShowLimitBarterInputs, getShouldShowLimitCashInputs } = usePrefinModalityRules();

  const [comment, setComment] = useState<string>('');

  const shouldShowLimitBarterInputs = useMemo(
    () =>
      changeCreditRequestStatusData.subDivisionId
        ? getShouldShowLimitBarterInputs(
            changeCreditRequestStatusData.modalityId,
            changeCreditRequestStatusData.subDivisionId,
          )
        : false,
    [changeCreditRequestStatusData.modalityId, changeCreditRequestStatusData.subDivisionId],
  );

  const shouldShowLimitCashInputs = useMemo(
    () =>
      changeCreditRequestStatusData.subDivisionId
        ? getShouldShowLimitCashInputs(
            changeCreditRequestStatusData.modalityId,
            changeCreditRequestStatusData.subDivisionId,
          )
        : null,
    [changeCreditRequestStatusData.modalityId, changeCreditRequestStatusData.subDivisionId],
  );

  const handleChangeMentions = (mentionedUserIds: string[]) => {
    setMentionedUserIds(mentionedUserIds);
  };

  async function handleGetPtax() {
    const lastPtax = await getLastPtax();
    form.setValue('ptax', lastPtax);
  }

  return (
    <Form form={form} name="status-change-form" className="form-secondary mt-4">
      <MentionProvider>
        <FormItem
          label={t('hooks.creditStatus.modal-status-comments-field')}
          name="comments"
          rules={{ required: true }}
        >
          <MentionInput
            className="line-clamp-3 h-[100px] overflow-y-auto"
            onChange={value => setComment(value)}
            value={comment}
            onMentionsChange={handleChangeMentions}
          />
        </FormItem>
      </MentionProvider>

      {fieldAmount.includes('enable_limit') ? (
        <FormItem
          label={t('hooks.creditStatus.modal-status-credit-limit-date-field')}
          name="credit_due_date"
          rules={{ required: true }}
        >
          <DatePicker
            className="w-full"
            disabledDates={day => moment(day).isBefore(moment().subtract(1, 'days'))}
            disabled={changeCreditRequestStatusData.isImported}
          />
        </FormItem>
      ) : null}

      {fieldAmount.includes('limit_expiration_date') ? (
        <>
          <FormItem
            label={t('hooks.creditStatus.modal-status-credit-limit-date-field_prepay')}
            name="limit_expiration_date"
            rules={{ required: true }}
          >
            <DatePicker disabled />
          </FormItem>

          <FormItem label="PTAX" name="ptax" rules={{ required: true }}>
            <InputMoney
              min="0.00001"
              maxLength={8}
              disabled
              className="w-full"
              suffix={
                <Button
                  title={t('pages.credit-request.form.get_ptax')}
                  variant="outline"
                  size="icon"
                  onClick={handleGetPtax}
                  disabled={isFetchingPtax}
                  isLoading={isFetchingPtax}
                >
                  <FaSearchDollar style={{ margin: 'none' }} />
                </Button>
              }
            />
          </FormItem>
        </>
      ) : null}

      {fieldAmount.includes('code_bp') && (
        <Loading isLoading={isFetchingSubsidiarySapCode}>
          <FormItem
            label={t('hooks.creditStatus.modal-status-bp-code-field')}
            name="code_bp"
            rules={{ required: true }}
          >
            <Input maxLength={255} disabled={changeCreditRequestStatusData.isImported} />
          </FormItem>
        </Loading>
      )}

      {fieldAmount.includes('approved_value') && (
        <>
          <ShouldRender
            condition={
              changeCreditRequestStatusData.operationId === OPERATION_INTERN_MARKET_ID ||
              changeCreditRequestStatusData.operationId === OPERATION_PREFIN_ID ||
              changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID
            }
          >
            <FormItem label="PTAX" name="ptax" rules={{ required: true }}>
              <InputMoney
                min="0.00001"
                maxLength={8}
                disabled
                className="w-full"
                suffix={
                  <Button
                    title={t('pages.credit-request.form.get_ptax')}
                    variant="outline"
                    size="icon"
                    onClick={handleGetPtax}
                    disabled={isFetchingPtax}
                    isLoading={isFetchingPtax}
                  >
                    <FaSearchDollar style={{ margin: 'none' }} />
                  </Button>
                }
              />
            </FormItem>
          </ShouldRender>
          <ShouldRender condition={shouldShowLimitInputs}>
            <FormItem
              label={`${
                changeCreditRequestStatusData.operationId === OPERATION_PREPAY_ID
                  ? t('hooks.creditStatus.modal-status-approved-value-field_prepay')
                  : t('hooks.creditStatus.modal-status-approved-value-field')
              }${changeCreditRequestStatusData.operationId === OPERATION_MTM_ID ? ' (US$)' : ''}`}
              name="approved_value"
              rules={{
                required: true,
                validate: {
                  isGreaterThanRequestedAmount: (value: any) => {
                    return changeCreditRequestStatusData.requestedAmount < value &&
                      changeCreditRequestStatusData.operationId !== OPERATION_MTM_ID
                      ? t('pages.credit-request.errors.approved_greater_requested')
                      : true;
                  },
                  isGreaterThanZero: (value: any) => {
                    return value <= 0 ? 'Digite um valor > 0' : true;
                  },
                },
              }}
            >
              <InputMoney
                min="0"
                minLength={0}
                disabled={shouldBlockApprovedValue || changeCreditRequestStatusData.isImported}
              />
            </FormItem>
          </ShouldRender>

          <ShouldRender condition={shouldShowLimitBarterInputs}>
            <FormItem
              label={t('hooks.creditStatus.modal-status-approved-value-barter-field')}
              name="approved_value_barter"
              rules={{
                required: true,
                validate: {
                  isGreaterThanRequestedAmountBarter: (value: any) => {
                    return changeCreditRequestStatusData.requestedAmountBarter < value
                      ? t('pages.credit-request.errors.approved_barter_greater_requested')
                      : true;
                  },
                  isGreaterThanZero: (value: any) => {
                    return value <= 0 ? 'Digite um valor > 0' : true;
                  },
                },
              }}
            >
              <InputMoney min="0" minLength={0} />
            </FormItem>
          </ShouldRender>

          <ShouldRender condition={!!shouldShowLimitCashInputs}>
            <FormItem
              label={t('hooks.creditStatus.modal-status-approved-value-cash-field')}
              name="approved_value_cash"
              rules={{
                required: true,
                validate: {
                  isGreaterThanRequestedAmountCash: (value: any) => {
                    return changeCreditRequestStatusData.requestedAmountCash < value
                      ? t('pages.credit-request.errors.approved_cash_greater_requested')
                      : true;
                  },
                  isGreaterThanZero: (value: any) => {
                    return value <= 0 ? 'Digite um valor > 0' : true;
                  },
                },
              }}
            >
              <InputMoney min="0" minLength={0} />
            </FormItem>
          </ShouldRender>
        </>
      )}

      {fieldAmount.includes('amount_paid') && (
        <FormItem
          label={t('hooks.creditStatus.modal-status-enabled-field')}
          name="amount_paid"
          rules={{
            required: true,
            validate: {
              isGreaterThanApprovedValue: (value: any) => {
                return changeCreditRequestStatusData.approvedValue < value
                  ? t('pages.credit-request.errors.approved_value')
                  : true;
              },
              isGreaterThanZero: (value: any) => {
                return value <= 0 ? 'Digite um valor > 0' : true;
              },
            },
          }}
        >
          <InputMoney />
        </FormItem>
      )}

      <FormItem
        name="documents"
        label={t('attachments')}
        description={
          isInvalidTargetStatusAttachmentRule ? t('hooks.creditStatus.modal-status-required-attachment') : ''
        }
        // validateStatus={isInvalidTargetStatusAttachmentRule ? 'error' : ''}
      >
        <Upload isPresign showUploadList />
      </FormItem>
    </Form>
  );
};
