import axios from 'axios';

import api from './api';

export default {
  urlUpload: async (fileName: string): Promise<any> => {
    return api.get(`attachment/presign-upload`, {
      params: {
        filename: fileName,
      },
    });
  },

  // view: async (value: any): Promise<any> => {
  //   return api.get(`attachment/presign-download/short-short`, {
  //     params: {
  //       filename: value,
  //       encoded: 1,
  //     },
  //   });
  // },

  upload: async (url: string, file: any): Promise<any> => {
    const config = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const response = await axios.put(url, file, config);
    return response;
  },

  download: async (value: any): Promise<any> => {
    return api.get(`attachment/presign-download/short`, {
      params: {
        filename: value,
      },
    });
  },

  destroy: async (name: string): Promise<any> => {
    return api.delete(`attachment`, {
      params: {
        filename: name,
      },
    });
  },
};
