import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { IconWithTooltip } from '@/components';
import { Button } from '@/components/ui/button';
import { DataTableAsync } from '@/components/ui/data-table';
import { Form } from '@/components/ui/form';
import { FormItem } from '@/components/ui/form-item';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { usePagination } from '@/hooks/usePagination';
import UserData from '@/types/User/UserData';
import { ColumnDef } from '@tanstack/react-table';

import { useGetUsersQuery } from './api/use-get-users.query';
import { useToggleActiveUserMutation } from './api/use-toggle-active-user.mutation';
import { SFilterContainer } from './styles';

export const UserTable = () => {
  const { dispatchPagination, paginationConfig } = usePagination();
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: {
      search: '',
    },
  });

  const [filters, setFilters] = useState({});

  const getUsersQuery = useGetUsersQuery({
    ...paginationConfig,
    ...filters,
    dispatchPagination,
  });

  const toggleUserActiveMutation = useToggleActiveUserMutation();

  const handleSwitchChange = async (record: any) => {
    // if (!permissions.includes('contract.active'))
    //   return ModalStatus({
    //     type: 'error',
    //     title: t('modal.error'),
    //     subTitle: t('noPermission'),
    //   });
    await toggleUserActiveMutation.mutateAsync(record.id);
    getUsersQuery?.refetch();
  };

  const columns = useMemo(
    (): ColumnDef<UserData>[] => [
      {
        header: t('name'),
        accessorKey: 'name',
        cell: ({ row }) => row.original.name,
      },
      {
        header: t('email'),
        accessorKey: 'email',
      },
      {
        header: t('role'),
        accessorKey: 'position',
        cell: ({ row }) => row.original.position?.name,
      },
      {
        header: t('is-external'),
        accessorKey: 'external_id',
        cell: ({ row }) => (row.original.external_id ? t('yes') : t('no')),
      },
      {
        header: t('regions'),
        accessorKey: 'regions',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredRegions =
            row.original.regions?.filter(subsidiary => subsidiary.name.toLowerCase().includes(search.toLowerCase())) ??
            [];

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {row.original.regions?.map(region => region.name).join(', ') ?? ''}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 font-bold uppercase text-primary">{t('regions')}</div>
                  <div className="pb-2">
                    <Input
                      ref={searchRef}
                      maxLength={255}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={t('search')}
                    />
                  </div>
                  <div className="flex max-h-[300px] flex-col gap-2 overflow-y-auto">
                    {filteredRegions.map(region => (
                      <div key={region.id}>{region.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        header: t('subsidiaries'),
        accessorKey: 'subsidiaries',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredSubsidiaries =
            row.original.subsidiaries?.filter(subsidiary =>
              subsidiary.name.toLowerCase().includes(search.toLowerCase()),
            ) ?? [];

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {row.original.subsidiaries?.map(subsidiary => subsidiary.name).join(', ') ?? ''}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 font-bold uppercase text-primary">{t('subsidiaries')}</div>
                  <div className="pb-2">
                    <Input
                      ref={searchRef}
                      maxLength={255}
                      onChange={e => setSearch(e.target.value)}
                      placeholder={t('search')}
                    />
                  </div>
                  <div className="flex max-h-[300px] flex-col gap-2 overflow-y-auto">
                    {filteredSubsidiaries.map(subsidiary => (
                      <div key={subsidiary.id}>{subsidiary.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        header: t('teams'),
        accessorKey: 'teams',
        cell: ({ row }) => {
          const [search, setSearch] = useState('');

          const searchRef = useRef<HTMLInputElement>(null);

          const filteredTeams =
            row.original.teams?.filter(teams => teams.name.toLowerCase().includes(search.toLowerCase())) ?? [];

          return (
            <TooltipProvider>
              <Tooltip
                onOpenChange={() => {
                  if (searchRef.current) {
                    searchRef.current.focus();
                  }
                  setSearch('');
                }}
              >
                <TooltipTrigger>
                  <div className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                    {row.original.teams?.map(team => team.name).join(', ') ?? ''}
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-2 font-bold uppercase text-primary">{t('teams')}</div>
                  <Input
                    ref={searchRef}
                    maxLength={255}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('search')}
                  />
                  <div className="flex max-h-[300px] flex-col gap-2 overflow-y-auto">
                    {filteredTeams.map(team => (
                      <div key={team.id}>{team.name}</div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        },
      },
      {
        header: t('active'),
        accessorKey: 'active',
        cell: ({ row }) => {
          return <Switch checked={!!row.original.active} onValueChange={() => handleSwitchChange(row.original)} />;
        },
      },
      {
        id: 'actions',
        enablePinning: true,
        accessorKey: 'action',
        header: t('actions'),
        enableSorting: false,
        cell: ({ row }) => {
          const record = row.original;
          return (
            <Link to={`${record.id}`} target="_blank" rel="noreferrer">
              <IconWithTooltip aria-label={t('edit')} action="edit" title={t('edit')} />
            </Link>
          );
        },
      },
    ],
    [getUsersQuery?.data],
  );

  return (
    <div className="flex h-full flex-col gap-2 overflow-y-hidden">
      <SFilterContainer>
        <Form
          form={form}
          name="filter-user"
          className="form-secondary form-filters grid-filters gap-2"
          onSubmit={values => setFilters(values)}
        >
          <div className="filter-search">
            <FormItem name="search" label={t('pages.admin.user.search')}>
              <Input />
            </FormItem>
          </div>
          <div className="filter-button">
            <FormItem label="" name="search" className="form-item-without-label">
              <Button variant="default" className="uppercase" type="submit">
                <FaSearch /> {t('form.actions.search')}
              </Button>
            </FormItem>
          </div>
          <div className="filter-clear">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setFilters({});
                form.reset();
              }}
            >
              {t('pages.admin.user.buttonClearFilter')}
            </Button>
          </div>
        </Form>
      </SFilterContainer>
      <DataTableAsync
        dispatchPagination={dispatchPagination}
        paginationConfig={paginationConfig}
        columns={columns}
        fixedHeader
        data={getUsersQuery?.data?.data ?? []}
        withPagination
        onSortingChange={newSort => {
          if (typeof newSort === 'function') {
            const newSorting = newSort(
              paginationConfig.sort?.map(sort => ({ id: sort.field, desc: sort.order === 'desc' })) ?? [],
            );
            dispatchPagination({
              payload: newSorting.map(sort => ({
                field: sort.id,
                order: sort.desc ? 'desc' : 'asc',
              })),
              type: 'SET_SORT',
            });
          }
        }}
        isLoading={getUsersQuery?.isLoading}
        isFetching={getUsersQuery?.isFetching}
        pageCount={getUsersQuery?.data?.last_page ?? 0}
        defaultColumn={{
          enableSorting: true,
        }}
      />
    </div>
  );
};
