import React from 'react';

import { TooltipContentProps, TooltipPortal, TooltipTriggerProps } from '@radix-ui/react-tooltip';

import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';

export const IconTooltip = ({
  trigger,
  content,
  title,
  children,
}: {
  title: string;
  trigger?: TooltipTriggerProps;
  content?: TooltipContentProps;
  children: React.ReactNode;
}) => {
  return (
    <Tooltip>
      <TooltipTrigger type="button" {...trigger}>
        {children}
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent variant="dark" {...content} className="z-50">
          {title}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
