import api from '@/services/api/api';
import { useQuery } from '@tanstack/react-query';

interface NotificationItem {
  id: string;
  name: {
    'pt-br': string;
    en: string;
  };
  message_type_id: string;
  created_at: string;
  updated_at: string;
  message_type: {
    id: string;
    name: string;
  };
}

export type NotificationTreeResponse = NotificationItem[];

type UseGetNotificationConfigurationTreeQueryProps = {
  onSuccess?: (data: NotificationTreeResponse) => void;
};

export const useGetNotificationConfigurationTreeQuery = ({
  onSuccess,
}: UseGetNotificationConfigurationTreeQueryProps) => {
  return useQuery({
    queryKey: ['admin/message/tree'],
    queryFn: async () => {
      const response = await api.get<NotificationTreeResponse>('admin/message/tree');

      onSuccess?.(response.data);
      return response.data;
    },
  });
};
