/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useCallback, useEffect, createContext, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { MODIFYING_KEYS } from '@/configs/constants';
import { useMentionContext } from '@/context/mention-input.provider';
import { cn } from '@/lib/utils';
import { useGetMentionUsersSelectQuery } from '@/pages/Admin/User/api/use-get-mention-users.query';
import UserData from '@/types/User/UserData';
import { useIntersectionObserver } from '@uidotdev/usehooks';

import { Avatar, AvatarFallback, AvatarImage } from './avatar';

interface MentionInputProps {
  value: string;
  onChange: (value: string) => void;
  onMentionsChange?: (mentionedUserIds: string[]) => void;
  className?: string;
}

// Custom debounce function
function debounce<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

function getCaret(el: HTMLDivElement) {
  let caretAt = 0;
  const sel = window.getSelection();

  if (!sel) return caretAt;

  if (sel.rangeCount === 0) {
    return caretAt;
  }

  const range = sel.getRangeAt(0);
  const preRange = range.cloneRange();
  preRange.selectNodeContents(el);
  preRange.setEnd(range.endContainer, range.endOffset);
  caretAt = preRange.toString().length;

  return caretAt;
}

const getCaretNode = (el: HTMLDivElement | null, position: number) => {
  if (!el) return null;
  let node: Node | null = null;
  el.childNodes.forEach(child => {
    if (child.nodeType === 3) {
      const text = child.textContent;
      if (text && position > 0) {
        node = child;
        position -= text.length;
      }
    }
    if (child.nodeType === 1) {
      if ((child as HTMLElement).outerText && position > 0) {
        node = child;
        position -= (child as HTMLElement).outerText.length;
      }
    }
  });
  return node;
};

const findNodeIndex = (element: Node, parentNode: Node) => {
  return Array.from(parentNode.childNodes).findIndex(node => {
    if (element.nodeType === 3) {
      return node.textContent?.includes(element.textContent ?? '');
    }

    if (node instanceof Element && element.parentNode instanceof Element && node.isEqualNode(element.parentNode)) {
      return true;
    }
    return false;
  });
};

function setCaret(el: HTMLDivElement, offset = 0, isRemoving = false) {
  const sel = window.getSelection();
  const range = document.createRange();
  if (offset < 0) return;
  if (offset === 0) {
    if (isRemoving) range.setStartAfter(el.childNodes[0]);
    else range.setStartAfter(el.childNodes[1]);
  } else {
    if (!isRemoving)
      range.setStart(
        el.childNodes.length > offset ? el.childNodes[offset] : el.childNodes[el.childNodes.length - 1],
        1,
      );
    else range.setStartAfter(el.childNodes[offset]);
  }

  if (sel) {
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

export const replaceMention = (value: string, data: { id: string; name: string }[], className?: string) => {
  const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  const nodeBuilder = (id: string) => {
    const user = data.find(user => user.id === id);
    if (!user) return '';
    const newMentionNode = document.createElement('span');

    newMentionNode.className = cn(`mention-highlight p-1 rounded-lg bg-primary text-white group`, className);
    newMentionNode.dataset.userId = user.id;
    newMentionNode.innerText = `@${user.name}`;

    return newMentionNode.outerHTML;
  };

  const splittedValue = value.split('@@');
  const html: string[] = [];
  splittedValue.forEach(value => {
    if (!value.match(uuidRegex)) return html.push(value);

    html.push(nodeBuilder(value));
  });
  return html.join('');
};

const reverseMention = (value: string) => {
  const regex = /<span\s+class="mention-highlight[^"]*"\s+data-user-id="([^"]+)"[^>]*>([^<]+)<\/span>&nbsp;/g;
  return value.replace(regex, (match, userId, content) => {
    return `@@${userId}@@ `;
  });
};

const extractMentionedUsers = (value: string): string[] => {
  const regex = /@@([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})@@/g;
  const matches = [...value.matchAll(regex)];
  return matches.map(match => match[1]);
};

const getOptimalPosition = (containerRect: DOMRect, suggestionsRect: DOMRect, viewportHeight = window.innerHeight) => {
  const spaceBelow = viewportHeight - containerRect.bottom;
  const spaceAbove = containerRect.top;

  // Default position styles
  let positionStyles = {
    top: '100%',
    bottom: 'auto',
    maxHeight: '15rem', // 60 in rem units
  };

  // If not enough space below, and more space above, position above
  if (spaceBelow < 240 && spaceAbove > spaceBelow) {
    positionStyles = {
      bottom: '100%',
      top: 'auto',
      maxHeight: `${Math.min(240, spaceAbove - 8)}px`,
    };
  } else {
    // Position below, but limit height if needed
    positionStyles.maxHeight = `${Math.min(240, spaceBelow - 8)}px`;
  }

  return positionStyles;
};

const portalRoot = document.createElement('div');
portalRoot.id = 'mention-portal';
portalRoot.style.pointerEvents = 'none';
document.body.appendChild(portalRoot);

const MentionList = ({
  users,
  selectedIndex,
  isFetchingNextPage,
  isFetching,
  isLoading,
  hasNextPage,
  onSelectUser,
  onLoadMore,
  intersectionRef,
  inputRect,
}: {
  users: { data: UserData }[];
  selectedIndex: number;
  isFetchingNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
  hasNextPage: boolean;
  onSelectUser: (user: UserData) => void;
  onLoadMore: () => void;
  intersectionRef: React.RefObject<HTMLDivElement>;
  inputRect: DOMRect | null;
}) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState<{
    top?: number;
    bottom?: number;
    left: number;
    width: number;
    direction: 'above' | 'below';
  }>();

  useEffect(() => {
    if (!inputRect) return;

    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    const newPosition = {
      left: inputRect.left,
      width: inputRect.width,
      direction: 'below' as 'above' | 'below',
      top: inputRect.bottom + 8,
    } as {
      left: number;
      width: number;
      direction: 'above' | 'below';
      top?: number;
      bottom?: number;
    };

    if (spaceBelow < 240 && spaceAbove > spaceBelow) {
      newPosition.direction = 'above';
      newPosition.top = undefined;
      newPosition.bottom = viewportHeight - inputRect.top + 8;
    }

    setPosition(newPosition);
  }, [inputRect]);

  useEffect(() => {
    const handleResize = () => setPosition(undefined);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!position || !inputRect) return <div />;

  return createPortal(
    <div
      className="shadow-lg pointer-events-auto fixed z-[9999] overflow-auto rounded-md border border-gray-200 bg-white"
      onWheel={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
      style={{
        left: position.left,
        top: position.direction === 'below' ? position.top : undefined,
        bottom: position.direction === 'above' ? position.bottom : undefined,
        width: position.width,
        maxHeight:
          position.direction === 'below'
            ? `${window.innerHeight - (position.top ?? 0) - 8}px`
            : `${(position.bottom ?? 0) - 8}px`,
      }}
    >
      {users.length === 0 ? (
        <div className="p-2 text-sm text-gray-500">
          {isFetchingNextPage || isFetching || isLoading ? `${t('loading')}...` : t('no-users-found')}
        </div>
      ) : (
        users.map(
          ({ data: user }, index) =>
            user && (
              <button
                type="button"
                key={user.id}
                data-index={index}
                className={cn(
                  'flex w-full cursor-pointer items-center p-2 hover:bg-gray-100 active:bg-orange-100',
                  index === selectedIndex && 'bg-orange-100',
                )}
                onClick={() => onSelectUser(user)}
              >
                <Avatar className="mr-2 h-6 w-6">
                  <AvatarImage src={user.photo} alt={user.name} />
                  <AvatarFallback>{user.name.slice(0, 2).toUpperCase()}</AvatarFallback>
                </Avatar>
                <span className="text-sm">{user.name}</span>
              </button>
            ),
        )
      )}
      {hasNextPage && (
        <div
          className="cursor-pointer p-2 text-center text-sm text-blue-500 hover:bg-gray-100"
          onClick={onLoadMore}
          ref={intersectionRef}
        >
          {isFetchingNextPage ? `${t('loading')}...` : t('load-more')}
        </div>
      )}
    </div>,
    portalRoot,
  );
};
export const sanitizePastedContent = (html: string): string => {
  // Create a temporary div to parse the HTML
  const temp = document.createElement('div');
  temp.innerHTML = html;

  // Remove any script tags for security
  const scripts = temp.getElementsByTagName('script');
  while (scripts[0]) {
    scripts[0].parentNode?.removeChild(scripts[0]);
  }

  // Get only the text content
  const text = temp.innerText || temp.textContent || '';

  // Remove extra line breaks and whitespace

  return text
    .replace(/(\r\n|\n|\r)/gm, '') // Remove all line breaks
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .trim(); // Remove leading/trailing whitespace
};

export function MentionInput({ value = '', onChange, onMentionsChange, className }: MentionInputProps) {
  const { t } = useTranslation();
  const [intersectionRef, entry] = useIntersectionObserver();
  const { setMention, setUsers, setSanitizedContent } = useMentionContext();

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const caretPos = useRef(0);
  const rangeRef = useRef<Range | null>(null);
  const modifyingKeyDownRef = useRef(false);
  const [key, setKey] = useState(new Date().getTime());

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching } = useGetMentionUsersSelectQuery(
    {
      search: searchTerm,
      limit: 10,
      active: 1,
    },
  );

  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [suggestionsStyles, setSuggestionsStyles] = useState({
    top: '100%',
    bottom: 'auto',
    maxHeight: '15rem',
  });
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

  const valueRef = useRef<string | null>(replaceMention(value, allUsers));

  const users = useMemo(() => data?.pages.flatMap(page => page.data) ?? [], [JSON.stringify(data)]);

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 1000),
    [],
  );

  const getCursorPosition = (element: HTMLDivElement) => {
    const selection = window.getSelection();
    if (!selection) return 0;
    const range = selection.getRangeAt(0);
    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(element);
    clonedRange.setEnd(range.endContainer, range.endOffset);

    const cursorPosition = clonedRange.toString().length;
    return cursorPosition;
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    modifyingKeyDownRef.current = MODIFYING_KEYS.includes(e.key);

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      if (selectedSuggestionIndex === -1) setSelectedSuggestionIndex(0);
      else if (e.key === 'ArrowUp' && selectedSuggestionIndex > 0) setSelectedSuggestionIndex(prev => prev - 1);
      else if (e.key === 'ArrowDown' && selectedSuggestionIndex < users.length - 1)
        setSelectedSuggestionIndex(prev => prev + 1);
    }
    if (showSuggestions) {
      if (e.key === 'Enter') {
        if (users[selectedSuggestionIndex]?.data) {
          e.preventDefault();
          handleSelectUser(users[selectedSuggestionIndex].data);
        }
      }
      if (e.key === 'Escape') {
        setShowSuggestions(false);
      }
    }
  };

  const handleRemoveMention = (e: React.KeyboardEvent<HTMLDivElement>, startContainer: Node) => {
    e.preventDefault();
    if (!inputRef.current) return;
    const nodeIndex = findNodeIndex(startContainer, inputRef.current);
    const node = inputRef.current.childNodes[nodeIndex];
    if (node) {
      node.remove();
      const nextNode = inputRef.current.childNodes[nodeIndex];
      if (nextNode) {
        nextNode.textContent = nextNode.textContent?.replace('&nbsp;', '') ?? '';
      }
    }
    onChange?.(reverseMention(inputRef.current.innerHTML));
  };

  const handleInputChange = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!inputRef.current) return;
    const caretAt = getCaret(inputRef.current);

    if (modifyingKeyDownRef.current) {
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const { startContainer } = range;
        if (startContainer.nodeType === 3 && startContainer.parentElement?.tagName === 'SPAN') {
          const text = startContainer.textContent;
          if (text && text.startsWith('@')) {
            let nodeIndex = -1;
            Array.from(inputRef.current.childNodes).findIndex((node, index) => {
              if (
                node instanceof Element &&
                startContainer.parentNode instanceof Element &&
                node.isEqualNode(startContainer.parentNode)
              ) {
                nodeIndex = index;
                return true;
              }
              return false;
            });

            handleRemoveMention(e, startContainer);
            setCaret(inputRef.current, nodeIndex - 1, true);
            return;
          }
        }
      }
    }

    caretPos.current = caretAt;
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const cursorPos = getCursorPosition(e.currentTarget);
      if (onChange) {
        setSanitizedContent(sanitizePastedContent(replaceMention(e.currentTarget.innerHTML, allUsers)));
        onChange(reverseMention(e.currentTarget.innerHTML));
      }

      const newValue = e.currentTarget.innerText;
      const lastAtSymbolIndex = newValue.lastIndexOf('@', cursorPos);

      if (lastAtSymbolIndex !== -1) {
        let isWithinMention = false;
        let textPosition = 0;

        const walkNodes = (node: Node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            textPosition += node.textContent?.length || 0;
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            if ((node as Element).classList?.contains('mention-highlight')) {
              const mentionLength = node.textContent?.length || 0;
              if (lastAtSymbolIndex >= textPosition && lastAtSymbolIndex < textPosition + mentionLength) {
                isWithinMention = true;
              }
              textPosition += mentionLength;
            } else {
              node.childNodes.forEach(walkNodes);
            }
          }
        };

        inputRef.current.childNodes.forEach(walkNodes);

        if (!isWithinMention) {
          rangeRef.current = selection.getRangeAt(0);
          setShowSuggestions(true);
          const searchStr = newValue.slice(lastAtSymbolIndex + 1, cursorPos);
          setSelectedSuggestionIndex(0);
          debouncedSearch(searchStr);
        }
      } else {
        setShowSuggestions(false);
      }
    }
  };

  const handleSelectUser = (user: UserData) => {
    const caretNode: Element | Node | null = getCaretNode(inputRef.current, caretPos.current);
    if (!caretNode || !inputRef.current) return;
    const caretNodeIndex = findNodeIndex(caretNode, inputRef.current);

    const text =
      (caretNode as HTMLElement).nodeType === 3 ? (caretNode as Text).data : (caretNode as HTMLElement).innerHTML;
    const splitText = text.split('@');
    if (!splitText[1]) {
      splitText[1] = '';
    }
    const previousText = splitText[0];

    // Encontra a posição do termo de busca
    const searchStart = splitText[1].indexOf(searchTerm);
    // Mantém o texto antes do termo de busca e depois dele
    const posteriorText =
      searchStart >= 0
        ? splitText[1].substring(0, searchStart) + splitText[1].substring(searchStart + searchTerm.length)
        : splitText[1];

    const newPreviousTextNode = document.createTextNode(previousText);
    const newPosteriorNode = document.createTextNode(`\u00A0${posteriorText}`);
    const newMentionNode = document.createElement('span');

    newMentionNode.className = 'mention-highlight p-1 rounded-lg bg-primary text-white relative';
    newMentionNode.dataset.userId = user.id;
    newMentionNode.innerText = `@${user.name}`;

    inputRef.current.replaceChild(newPosteriorNode, caretNode);
    inputRef.current.insertBefore(newMentionNode, newPosteriorNode);
    inputRef.current.insertBefore(newPreviousTextNode, newMentionNode);

    const newValue = inputRef.current.innerHTML;
    const newCaretPos = getCaret(inputRef.current);
    caretPos.current = newCaretPos;
    valueRef.current = newValue;
    inputRef.current.innerHTML = newValue;
    inputRef.current.focus();
    rangeRef.current = null;
    setTimeout(() => {
      if (inputRef.current) setCaret(inputRef.current, caretNodeIndex + (caretNodeIndex >= 0 ? 2 : 0));
    }, 10);

    if (onChange) {
      setSanitizedContent(sanitizePastedContent(replaceMention(inputRef.current.innerText, allUsers)));
      onChange(reverseMention(inputRef.current.innerHTML));
    }
    setShowSuggestions(false);
    setSearchTerm('');
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();

    // Get clipboard content
    const pastedData = e.clipboardData.getData('text/html') || e.clipboardData.getData('text');

    // Sanitize the pasted content
    const sanitizedContent = sanitizePastedContent(pastedData);

    // Insert at cursor position
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(sanitizedContent));
    }

    // Trigger input change
    if (inputRef.current) {
      setSanitizedContent(sanitizePastedContent(replaceMention(e.currentTarget.innerHTML, allUsers)));
      onChange(reverseMention(inputRef.current.innerHTML));
    }
  };

  useEffect(() => {
    if (data?.pages) {
      const newUsers = data.pages
        .flatMap(page => page.data)
        .map(data => data.data)
        .filter((u): u is UserData => !!u);

      setAllUsers(prevUsers => {
        const uniqueUsers = new Map([...prevUsers, ...newUsers].map(user => [user.id, user]));
        return Array.from(uniqueUsers.values());
      });
    }
  }, [JSON.stringify(data?.pages)]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      const isInsidePortal = portalRoot === target || portalRoot.contains(target);
      const isInsideInput = inputRef.current?.contains(target);

      if (!isInsidePortal && !isInsideInput) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (onMentionsChange) {
      const mentionedUsers = extractMentionedUsers(value);
      onMentionsChange(mentionedUsers);
    }
  }, [value]);

  // ? reseta a chave para que o componente seja recriado assim limpando o valor
  useEffect(() => {
    if (!value || value === '') {
      setKey(new Date().getTime());
      valueRef.current = '';
    }
  }, [value]);

  useEffect(() => {
    setUsers(users.map(user => user.data).filter((u): u is UserData => !!u));
  }, [JSON.stringify(users)]);

  useEffect(() => {
    if (showSuggestions && inputRef.current && suggestionsRef.current) {
      const containerRect = inputRef.current.getBoundingClientRect();
      const suggestionsRect = suggestionsRef.current.getBoundingClientRect();
      const newStyles = getOptimalPosition(containerRect, suggestionsRect);
      setSuggestionsStyles(newStyles);
    }
  }, [showSuggestions, searchTerm]);

  useEffect(() => {
    if (entry?.isIntersecting) {
      fetchNextPage();
    }
  }, [entry?.isIntersecting]);

  useEffect(() => {
    if (selectedSuggestionIndex > -1) {
      suggestionsRef.current?.getElementsByTagName('button')?.[selectedSuggestionIndex]?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [selectedSuggestionIndex]);

  return (
    <div className="relative">
      <div
        ref={inputRef}
        contentEditable
        className={cn(
          'min-h-[40px] w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500',
          className,
        )}
        onInput={handleInputChange}
        onPaste={handlePaste}
        onKeyDown={e => handleKeyDown(e as unknown as KeyboardEvent)}
        key={key}
        dangerouslySetInnerHTML={{ __html: value ? (valueRef.current ?? '') : '' }}
      />

      {showSuggestions && (
        <MentionList
          users={users}
          selectedIndex={selectedSuggestionIndex}
          isFetchingNextPage={isFetchingNextPage}
          isFetching={isFetching}
          isLoading={isLoading}
          hasNextPage={!!hasNextPage}
          onSelectUser={handleSelectUser}
          onLoadMore={fetchNextPage}
          intersectionRef={intersectionRef}
          inputRect={inputRef.current?.getBoundingClientRect()}
        />
      )}
    </div>
  );
}

export type MentionData = {
  userId: string;
  position: {
    left: number;
    top: number;
  };
};

export type MentionDisplayProps = {
  comment: string;
  mentioned_users: Pick<UserData, 'name' | 'email' | 'id'>[];
  messageClassName: string;
};

export const MentionDisplay = ({ comment, mentioned_users, messageClassName }: MentionDisplayProps) => {
  const { setUsers } = useMentionContext();

  return (
    <span
      onMouseEnter={() => {
        setUsers(mentioned_users ?? []);
      }}
      dangerouslySetInnerHTML={{
        __html: replaceMention(comment, mentioned_users ?? [], messageClassName),
      }}
    />
  );
};

export const MentionLength = ({ maxLength }: { maxLength: number }) => {
  const { sanitizedContent } = useMentionContext();

  return (
    <span>
      {sanitizedContent.length}/{maxLength}
    </span>
  );
};
