import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { MentionData } from '@/components/ui/mention-input';
import { MentionHover } from '@/components/ui/mention-input-tooltip';
import UserData from '@/types/User/UserData';

type MentionContextProps = {
  mention: MentionData | null;
  setMention: (mention: MentionData | null) => void;
  users: Pick<UserData, 'name' | 'email' | 'id'>[];
  setUsers: (users: Pick<UserData, 'name' | 'email' | 'id'>[]) => void;
  setSanitizedContent: (content: string) => void;
  sanitizedContent: string;
};

const MentionContext = createContext<MentionContextProps>({} as MentionContextProps);

export const useMentionContext = () => {
  const context = useContext(MentionContext);
  if (!context) {
    throw new Error('useMentionContext must be used within a MentionProvider');
  }
  return context;
};

export const MentionProvider = ({ children }: { children: React.ReactNode }) => {
  const [mention, setMention] = useState<MentionData | null>(null);
  const [users, setUsers] = useState<Pick<UserData, 'name' | 'email' | 'id'>[]>([]);
  const [sanitizedContent, setSanitizedContent] = useState('');

  const user = users.find(user => user.id === mention?.userId);

  const realPosition = useMemo(() => {
    const mousePosition = mention?.position;
    return {
      left: mousePosition?.left ?? 0,
      top: mousePosition?.top ?? 0,
    };
  }, [mention]);

  useEffect(() => {
    const handleMouseEnter = (e: Event) => {
      const mouseEvent = e as MouseEvent;
      const mention = e.target as HTMLElement;
      const { userId } = mention.dataset;

      if (!userId) {
        setMention(null);
        return;
      }

      setMention({
        userId,
        position: {
          left: mouseEvent.clientX,
          top: mouseEvent.clientY,
        },
      });
    };

    document.addEventListener('mousemove', handleMouseEnter);

    return () => {
      document.removeEventListener('mousemove', handleMouseEnter);
    };
  }, []);

  return (
    <MentionContext.Provider
      value={{
        mention,
        setMention,
        users,
        setUsers,
        setSanitizedContent,
        sanitizedContent,
      }}
    >
      {children}
      {user ? <MentionHover user={user} position={realPosition} /> : null}
    </MentionContext.Provider>
  );
};
