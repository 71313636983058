import api from '@/services/api/api';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export type DTOGetNotificationConfiguration = {
  user_message: {
    message_id: string;
    name: string | null;
    message_type_id: string;
    created_at: string;
    updated_at: string;
    via_email: boolean;
    via_broadcast: boolean;
    via_teams: boolean;
  }[];
  stop_broadcast_notifications: boolean;
  stop_email_notifications: boolean;
  stop_teams_notifications: boolean;
};

type Props = {
  userId: string;
  onSuccess?: (data: DTOGetNotificationConfiguration) => void;
} & Omit<
  UseQueryOptions<DTOGetNotificationConfiguration, unknown, DTOGetNotificationConfiguration, string[]>,
  'queryKey' | 'queryFn'
>;

export const useGetNotificationConfigurationQuery = ({ userId, onSuccess, ...options }: Props) => {
  const url = `admin/user/${userId}/message`;
  return useQuery({
    ...options,
    queryKey: [url],
    enabled: !!userId && options.enabled,
    queryFn: async () => {
      const response = await api.get<DTOGetNotificationConfiguration>(url);

      onSuccess?.(response.data);
      return response.data as DTOGetNotificationConfiguration;
    },
  });
};
