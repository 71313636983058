/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from '@/components/PermissionedComponent';
import { useCache } from '@/hooks/cache';
import { useFetchFiles } from '@/hooks/useFetchFiles';
import { cn } from '@/lib/utils';
import { CloudUpload, FileCheck, FileX, X } from 'lucide-react';
import { v4 as uuid } from 'uuid';

import { Button } from './button';
import { FormItem } from './form-item';
import Loading from './loading';
import { Select } from './select';
import { Separator } from './separator';
import { useToast } from './use-toast';

export type Upload = {
  classNames?: {
    container?: string;
    root?: string;
  };
  onChange?: ({ target: { name, value } }: { target: { name: string; value: File[] } }) => void;
  onValueChange?: (value: File[]) => void;
  value?: File[];
  name?: string;
  onError?: (error: string) => void;
  showUploadList?: boolean;
  allowedExtensions?: string[];
  maxFiles?: number;
  disabled?: boolean;
  onUpload?: (file: File[]) => void;
  description?: string;
  permissions?: string | string[];
  fieldsConfig?: {
    type?: boolean;
  };
  isPresign?: boolean;
  additionalFields?: (file: File, index: number) => React.ReactNode;
  shortDetails?: boolean;
};

export const Upload = ({
  name,
  onChange,
  classNames,
  value = [],
  onError,
  showUploadList,
  allowedExtensions,
  maxFiles = 0,
  disabled,
  onValueChange,
  onUpload,
  description,
  permissions,
  fieldsConfig,
  isPresign,
  additionalFields,
  shortDetails = false,
}: Upload) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const cache = useCache();
  const { handleGetUrlUpload } = useFetchFiles();

  const inputRef = useRef<HTMLInputElement>(null);

  const [_, setDragging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [id] = useState(uuid());

  const isDisabled = (value?.length >= maxFiles && !!maxFiles) || disabled;

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      await processFiles(Array.from(e.dataTransfer.files));
      e.dataTransfer.clearData();
    }
  };

  const handlePresignUpload = async (file: any) => {
    const response = await handleGetUrlUpload(file.name, file, setLoadingFile);
    if (response === null) {
      setHasError(true);
      setMessageError(t('components.upload.errorUpload.title', { fileName: file.name }));
      removeFile(value.indexOf(file));
    }
    return response.filename;
  };

  const processFiles = async (files: File[]) => {
    setHasError(false);
    const validFiles = files.filter(file => handleValidateFile(file));

    if (validFiles.length) {
      if (isPresign) {
        const presignPromises = validFiles.map(file => handlePresignUpload(file));
        const presignedFileNames = await Promise.all(presignPromises);

        const validFilesClone = validFiles.map(
          (file, index) => new File([file], presignedFileNames[index] || file.name),
        );

        if (onValueChange) onValueChange([...(value || []), ...validFilesClone]);
        if (onUpload) onUpload(validFilesClone);

        if (onChange && name) onChange({ target: { name, value: [...(value || []), ...validFilesClone] } });
      } else {
        if (onValueChange) onValueChange([...(value || []), ...validFiles]);
        if (onUpload) onUpload(validFiles);

        if (onChange && name) onChange({ target: { name, value: [...(value || []), ...validFiles] } });
      }
    }
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];
    await processFiles(Array.from(files));
  };

  const handleValidateFile = (file: File) => {
    if (file.size / 1024 > 50000) {
      setHasError(true);
      setMessageError(t('components.upload.fileTooBig.title'));
      if (onError) onError(t('components.upload.fileTooBig.title'));
      toast({
        title: t('components.upload.fileTooBig.title'),
        description: t('components.upload.fileTooBig.description'),
      });
      return false;
    }
    return true;
  };

  const removeFile = (index: number) => {
    if (onChange && name) {
      const newValue = value.filter((_, i) => i !== index);
      if (onValueChange) onValueChange(newValue);
      onChange({ target: { name, value: newValue } });
      if (inputRef.current) inputRef.current.value = '';
    }
  };

  return (
    <div
      className={cn(
        'flex min-h-fit w-full flex-wrap items-center overflow-hidden rounded-lg border border-dashed bg-white',
        hasError ? 'border-red-600' : '',
      )}
    >
      <PermissionedComponent permission={permissions}>
        <Loading isLoading={loadingFile} className="w-full">
          <label
            htmlFor={id}
            className={cn(
              'custom-file-input w-full overflow-hidden [&:focus-within>*]:bg-blue-100',
              classNames?.container,
            )}
          >
            <div
              className="w-full"
              onDragOver={handleDrag}
              onDragEnter={handleDragIn}
              onDragLeave={handleDragOut}
              onDrop={handleDrop}
            >
              <input
                id={id}
                type="file"
                ref={inputRef}
                accept={allowedExtensions?.join(',')}
                className="h-0 w-0 opacity-0"
                disabled={isDisabled}
                onChange={handleChangeFile}
                multiple={maxFiles !== 1}
              />
              <div
                className={cn(
                  'text-mg shadow-sm relative flex w-full flex-col items-center gap-2 border-b border-dashed border-gray-100 p-4 font-bold text-gray-400',
                  isDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
                  hasError ? 'text-red-600' : '',
                )}
              >
                {hasError ? <FileX size={30} /> : <CloudUpload size={30} />}
                <span className="text-center">{hasError ? messageError : t('components.upload.description')}</span>
                {description ? <span className="text-center text-gray-500">{description}</span> : null}
                {!hasError && allowedExtensions?.length ? (
                  <span className="font-sm text-center font-normal text-gray-400">
                    {t('components.upload.allowedExtensions', { extensions: allowedExtensions.join(', ') })}
                  </span>
                ) : null}
              </div>
            </div>
          </label>
        </Loading>
      </PermissionedComponent>

      <div className={cn('w-full transition-all', showUploadList && value?.length ? 'h-auto' : 'h-0')}>
        <div className="text-mg flex max-h-[500px] flex-col items-center gap-2 overflow-y-auto p-4 font-bold text-gray-500">
          {value &&
            value.map((file, index) => (
              <React.Fragment key={file.name}>
                <div className="flex w-full min-w-fit items-center justify-between gap-2 font-normal" key={file.name}>
                  <div className="flex h-full items-center gap-4">
                    <FileCheck size={30} />
                    <div className="flex h-full flex-wrap items-center text-start text-xs md:gap-8">
                      <div className={cn('flex h-full items-center gap-8', shortDetails ? 'flex-col' : '')}>
                        <span className="flex h-full flex-col">
                          <div className="font-bold">{t('components.upload.fileName')}</div>
                          <div className="max-w-[400px] overflow-hidden overflow-ellipsis break-words">
                            {isPresign ? file.name.replace(/[A-z0-9][^_]*_/g, '') : file.name}
                          </div>
                        </span>
                        <span>
                          <div className="font-bold">{t('components.upload.fileSize')}</div>
                          <div>{(file.size / 1024).toFixed(2)} KB</div>
                        </span>
                      </div>
                      <Separator orientation="vertical" className="border-gray-200" />
                      {fieldsConfig?.type ? (
                        <span>
                          <FormItem
                            name={`${name}[${index}].type_id`}
                            rules={{ required: true }}
                            label={<div className="font-bold">{t('file-identification')}</div>}
                            readable={t('file-identification')}
                            className="w-[200px]"
                          >
                            <Select className="w-full" options={cache.getCreditOptions('document_type_credit')} />
                          </FormItem>
                        </span>
                      ) : null}
                      {additionalFields ? additionalFields(file, index) : null}
                    </div>
                  </div>
                  <Button
                    variant="destructive"
                    size="sm"
                    type="button"
                    className="p-1"
                    onClick={() => removeFile(value.indexOf(file))}
                  >
                    <X size={18} />
                  </Button>
                </div>
                {index !== value.length - 1 ? <Separator /> : null}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};
