import React from 'react';
import { createPortal } from 'react-dom';

import UserData from '@/types/User/UserData';

import { Avatar, AvatarFallback, AvatarImage } from './avatar';

export const MentionHover = ({
  user,
  position,
}: {
  user: Pick<UserData, 'name' | 'email' | 'id' | 'photo'>;
  position: { left: number; top: number };
}) => {
  return createPortal(
    <div
      data-user-id={user.id}
      className="mention-hover absolute z-[100] flex cursor-context-menu items-center gap-1 rounded-md bg-white p-4 drop-shadow-md"
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      <Avatar className="mr-2 h-10 w-10">
        <AvatarImage src={user.photo} alt={user.name} />
        <AvatarFallback>{user.name.slice(0, 2).toUpperCase()}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <span>{user?.name}</span>
        <span className="opacity-70">{user?.email}</span>
      </div>
    </div>,
    document.body,
  );
};
