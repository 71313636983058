import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useAlertDialog } from '@/components/ui/alert-dialog';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import appConfigs from '@/configs/app';
import { useCheckSelectedOperation } from '@/pages/CreditRequest/rules/general/useCheckSelectedOperation';
import { usePrefinModalityRules } from '@/pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import { Operations, SubDivisions } from '@/pages/CreditRequest/store/slices/credit-request-slices/division.slice';
import { useQueryClient } from '@tanstack/react-query';
import moment, { Moment } from 'moment';

import DTOCreditOperation from '../@types/dtos/credit-request/DTOCreditOperation';
import DTOCreditPhase from '../@types/dtos/credit-request/DTOCreditPhase';
import DTOStatus from '../@types/dtos/credit-request/DTOStatus';
import DTOTeam from '../@types/dtos/credit-request/DTOTeam';
import DTOCurrencyType from '../@types/dtos/DTOCurrencyType';
import CreditStatusContextData, { TeamStatusOptionType, TeamStatusRulesTarget } from '../@types/hooks/creditStatus';
import { Button, Spin } from '../components';
import { SelectOptionType } from '../components/Select/types';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_MTM_ID,
  OPERATION_PREFIN_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
  TEAM_STATUS_ADVANCE_PROGRAMMING_CONTRACTS_SOLICITATION_ID,
  TEAM_STATUS_APPROVED_CREDIT_FUTURE_HARVEST_CREDIT,
  TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID,
  TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG,
  TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID,
  TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG,
  TEAM_STATUS_LIMIT_ENABLING_CONTRACT_ID,
  TEAM_STATUS_LIMIT_ENABLING_CONTRACT_SLUG,
  TEAM_STATUS_ON_QUEUE_PREFIN_ID,
} from '../configs/constants';
import creditApi from '../services/api/credit';
import { formatValuesToFormData } from '../services/form';
import { getTranslations } from '../services/translations';
import TeamStatusData, { TeamStatusRulesData } from '../types/CreditRequest/DTOTeamStatus';
import { Formatter } from '../utils/Formatter';
import { useAuth } from './auth';
import { useCache } from './cache';
import { CreditStatusForm } from './creditStatus.form';
import { useCreditRequest } from './fetch/useCreditRequest';
import { usePage } from './page';

export type ChangeStatusOptions = { shouldReloadAfterSuccess?: boolean } | undefined;

export interface ChangeCreditRequestStatusDataProps {
  operationId: Operations | null;
  currentStatusId: string;
  newStatusId: string;
  creditRequestId: string;
  creditRequestNumber: number | string;
  currencyTypeSlug: string;
  requestedAmount: number;
  requestedAmountBarter: number;
  requestedAmountCash: number;
  approvedValue: number;
  approvedValueBarter: number;
  approvedValueCash: number;
  enabledValue: number;
  creditDueDate: string;
  modalityId: string;
  datasource: string;
  subDivisionId: SubDivisions | null;
  providerSapCode?: string;
  isImported?: boolean;
  limitExpirationDate?: Moment | string;
  ptax?: number;
}

const initialChangeCreditRequestStatusData = {
  operationId: null,
  currentStatusId: '',
  newStatusId: '',
  creditRequestId: '',
  creditRequestNumber: '',
  currencyTypeSlug: '',
  approvedValue: 0,
  approvedValueBarter: 0,
  approvedValueCash: 0,
  requestedAmount: 0,
  requestedAmountBarter: 0,
  requestedAmountCash: 0,
  enabledValue: 0,
  modalityId: '',
  subDivisionId: null,
  providerSapCode: '',
  isImported: false,
  datasource: '',
} as ChangeCreditRequestStatusDataProps;

const CreditStatusContext = createContext<CreditStatusContextData>({} as CreditStatusContextData);

export type CreditStatusChangeFormValues = {
  team_status_id: string;
  amount_paid: number;
  approved_value: number;
  approved_value_barter: number;
  approved_value_cash: number;
  limit_expiration_date: string;
  documents: File[];
  ptax: number;
  comments: string;
  credit_due_date: string;
  code_bp: string;
};

const initialCreditStatusChangeFormValues = {
  team_status_id: '',
  amount_paid: 0,
  approved_value: 0,
  approved_value_barter: 0,
  approved_value_cash: 0,
  limit_expiration_date: '',
  documents: [],
  ptax: 0,
  comments: '',
  credit_due_date: '',
  code_bp: '',
} as CreditStatusChangeFormValues;

const CreditStatusProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();

  const { getSubsidiarySapCode, isFetchingSubsidiarySapCode } = useCreditRequest();
  const navigate = useNavigate();
  const { alert } = useAlertDialog();
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const { team, credit_status, team_credit_status, team_credit_status_phase, operation, position, currency_type } =
    useCache();
  const { alertStatus } = usePage();

  const [targetStatus, setTargetStatus] = useState<TeamStatusData>({} as TeamStatusData);
  const [changeCreditRequestStatusData, setChangeCreditRequestStatusData] =
    useState<ChangeCreditRequestStatusDataProps>(initialChangeCreditRequestStatusData);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [teamStatusHasChanged, setTeamStatusHasChanged] = useState<boolean>(false);
  const [_, setSubsidiarySapCode] = useState<string>('');
  const [shouldBlockApprovedValue, setShouldBlockApprovedValue] = useState<boolean>(false);
  const [shouldReloadAfterSuccess, setShouldReloadAfterSuccess] = useState<boolean>(false);
  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>([]);

  const form = useForm<CreditStatusChangeFormValues>({
    defaultValues: initialCreditStatusChangeFormValues,
  });
  const documents = form.watch('documents');

  const { getShouldShowLimitInputs } = usePrefinModalityRules();

  const { checkIsPrefin, checkIsPrefinSugarMills } = useCheckSelectedOperation();

  const shouldShowLimitInputs = useMemo(
    () =>
      changeCreditRequestStatusData.operationId
        ? checkIsPrefin(changeCreditRequestStatusData.operationId) ||
          checkIsPrefinSugarMills(changeCreditRequestStatusData.operationId)
          ? changeCreditRequestStatusData.subDivisionId
            ? getShouldShowLimitInputs(
                changeCreditRequestStatusData.modalityId,
                changeCreditRequestStatusData.subDivisionId,
              )
            : false
          : true
        : false,
    [changeCreditRequestStatusData.modalityId, changeCreditRequestStatusData.subDivisionId],
  );

  const getTeam = useCallback(
    (id: string, likeOptions = false) => {
      const findTeam = team.find((teamItem: DTOTeam) => teamItem.id === id);

      if (findTeam) {
        const teamItem = { ...findTeam, translations: findTeam.translations };

        if (likeOptions === true) {
          return {
            key: teamItem.id,
            label: getTranslations(teamItem, 'title', i18n.language, 'name'),
            value: teamItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamItem as DTOTeam;
      }

      return null;
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getTeamBySlug = useCallback(
    (slug: string, likeOptions = false) => {
      const findTeam = team.find((teamItem: DTOTeam) => teamItem.slug === slug);

      if (findTeam) {
        const teamItem = { ...findTeam, translations: findTeam.translations };

        if (likeOptions === true) {
          return {
            key: teamItem.id,
            label: getTranslations(teamItem, 'title', i18n.language, 'name'),
            value: teamItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamItem as DTOTeam;
      }

      return null;
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getCurrencyType = useCallback(
    (id: string, likeOptions = false) => {
      const findCurrency = currency_type.find((currencyItem: DTOCurrencyType) => currencyItem.id === id);

      if (findCurrency) {
        const currencyItem = { ...findCurrency, translations: findCurrency.translations };

        if (likeOptions === true) {
          return {
            key: currencyItem.id,
            label: currencyItem.slug,
            value: currencyItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return currencyItem as DTOCurrencyType;
      }

      return null;
    },
    // eslint-disable-next-line
    [currency_type],
  );

  const getOperation = useCallback(
    (id: string, likeOptions = false) => {
      const findOperation = operation.find((operationItem: DTOCreditOperation) => operationItem.id === id);

      if (findOperation) {
        const operationItem = { ...findOperation, translations: findOperation.translations };

        if (likeOptions === true) {
          return {
            key: operationItem.id,
            label: getTranslations(operationItem, 'title', i18n.language, 'name'),
            value: operationItem.id,
            disabled: true,
          } as SelectOptionType;
        }

        return operationItem as DTOCreditOperation;
      }

      return null;
    },
    // eslint-disable-next-line
    [operation, getTranslations, i18n],
  );

  const getStatus = useCallback(
    (id: string, likeOptions = false) => {
      const findCreditStatus = credit_status.find((creditStatusItem: DTOStatus) => creditStatusItem.id === id);

      if (findCreditStatus) {
        const status = { ...findCreditStatus, translations: findCreditStatus.translations };

        if (likeOptions === true) {
          return {
            key: status.id,
            label: getTranslations(status, 'title', i18n.language, 'name'),
            value: status.id,
            disabled: true,
          } as SelectOptionType;
        }

        return status as DTOStatus;
      }

      return null;
    },
    // eslint-disable-next-line
    [credit_status, getTranslations, i18n],
  );

  const getTeamStatus = useCallback(
    (id: string, likeOptions = false) => {
      const findTeamStatus = team_credit_status?.find((teamStatusItem: TeamStatusData) => teamStatusItem.id === id);

      if (findTeamStatus) {
        const teamStatus = { ...findTeamStatus, translations: findTeamStatus.translations };

        if (likeOptions === true) {
          return {
            key: teamStatus.id,
            label: getTranslations(teamStatus, 'title', i18n.language, 'name'),
            value: teamStatus.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamStatus as TeamStatusData;
      }

      return null;
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getTeamStatusBySlug = useCallback(
    (slug: string, likeOptions = false) => {
      const findTeamStatus = team_credit_status?.find((teamStatusItem: TeamStatusData) => teamStatusItem.slug === slug);

      if (findTeamStatus) {
        const teamStatus = { ...findTeamStatus, translations: findTeamStatus.translations };

        if (likeOptions === true) {
          return {
            key: teamStatus.id,
            label: getTranslations(teamStatus, 'title', i18n.language, 'name'),
            value: teamStatus.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamStatus as TeamStatusData;
      }

      return null;
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeams = useCallback(
    (likeOptions = false) => {
      const listStatus = team;

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: DTOTeam) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));
        return listOptions as TeamStatusOptionType[];
      }
      return listStatus as DTOTeam[];
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getTeamsByPhaseId = useCallback(
    (phaseId: string) => {
      // eslint-disable-next-line prefer-const
      let teamFiltered: string[] = [];
      team_credit_status.forEach((item: TeamStatusData) => {
        if (item.phase_id === phaseId) {
          teamFiltered.push(item.team_id);
        }
      });

      const result = getListOfTeams() as any;
      return result
        .filter((item: DTOTeam) => teamFiltered.includes(item.id ?? ''))
        .filter((t: any) => team.find(team => team.id === t.id));
    },
    // eslint-disable-next-line
    [getListOfTeams, team_credit_status, team],
  );

  const getListOfTeamStatusOrderedByPhase = useCallback(
    (likeOptions = false, phaseId: string) => {
      let listStatus = team_credit_status;

      team_credit_status.forEach((item: TeamStatusData) => {
        if (item.phase_id === phaseId) {
          listStatus.push(item);
        }
      });

      // eslint-disable-next-line func-names
      listStatus = listStatus.sort(function (a, b) {
        return a.order - b.order;
      });

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: TeamStatusData) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listStatus as TeamStatusData[];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeamsOrdered = useCallback(
    (likeOptions = false) => {
      // eslint-disable-next-line func-names
      const listTeams = team?.sort(function (a, b) {
        return a.order - b.order;
      });

      if (likeOptions === true) {
        const listOptions = listTeams.map((item: DTOTeam) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listTeams as DTOTeam[];
    },
    // eslint-disable-next-line
    [team, getTranslations, i18n],
  );

  const getPhase = useCallback(
    (id: string, likeOptions = false) => {
      const findTeamStatus = team_credit_status_phase.find((phase: DTOCreditPhase) => phase.id === id);

      if (findTeamStatus) {
        const teamStatus = { ...findTeamStatus, translations: findTeamStatus.translations };

        if (likeOptions === true) {
          return {
            key: teamStatus.id,
            label: getTranslations(teamStatus, 'title', i18n.language, 'name'),
            value: teamStatus.id,
            disabled: true,
          } as SelectOptionType;
        }

        return teamStatus as DTOCreditPhase;
      }

      return null;
    },
    // eslint-disable-next-line
    [team_credit_status_phase, getTranslations, i18n],
  );

  const getListOfPhasesOrdered = useCallback(
    (likeOptions = false) => {
      // eslint-disable-next-line func-names
      const listPhases = team_credit_status_phase?.sort(function (a, b) {
        return Number(a.order) - Number(b.order);
      });

      if (likeOptions === true) {
        const listOptions = listPhases.map((item: DTOCreditPhase) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listPhases as DTOCreditPhase[];
    },
    // eslint-disable-next-line
    [team_credit_status_phase, getTranslations, i18n],
  );

  const getListOfTeamStatus = useCallback(
    (likeOptions = false) => {
      const listStatus = team_credit_status;

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: TeamStatusData) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listStatus as TeamStatusData[];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeamStatusOrdered = useCallback(
    (likeOptions = false, teamId = null, phaseId = null) => {
      let listStatus = team_credit_status;

      if (teamId !== null && phaseId !== null) {
        listStatus = team_credit_status.filter(
          teamStatus => teamStatus.team_id === teamId && teamStatus.phase_id === phaseId,
        );
      }

      // eslint-disable-next-line func-names
      listStatus = listStatus.sort(function (a, b) {
        return a.order - b.order;
      });

      if (likeOptions === true) {
        const listOptions = listStatus.map((item: TeamStatusData) => ({
          key: item.id,
          label: getTranslations(item, 'title', i18n.language, 'name'),
          value: item.id,
          slug: item.slug,
          order: item.order,
        }));

        return listOptions as TeamStatusOptionType[];
      }

      return listStatus as TeamStatusData[];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  const getListOfTeamStatusOrderedTargetRules = useCallback(
    (teamId: string | null = null) => {
      let listStatus = team_credit_status;

      if (teamId !== null) {
        listStatus = team_credit_status.filter(teamStatus => teamStatus.team_id === teamId);
      }

      // eslint-disable-next-line func-names
      listStatus = listStatus.sort(function (a, b) {
        return a.order - b.order;
      });

      listStatus = listStatus.map(item => {
        const rules = item.rules_target;
        let option: TeamStatusRulesTarget = { ...item, target_team_status: [] };

        rules?.forEach(ruleItem => {
          const foundOperation = operation.find(operationItem => operationItem.id === ruleItem.operation_id);
          const teamStatus = getTeamStatus(ruleItem.team_credit_status_target_id) as TeamStatusData;

          if (!ruleItem.position_id && foundOperation) {
            option = {
              ...option,
              target_team_status: [
                ...(option.target_team_status ?? []),
                {
                  ...teamStatus,
                  operation: foundOperation,
                },
              ],
            };
          }

          if (user.position_id === ruleItem.position_id && foundOperation) {
            const findPosition = position.find(positionItem => positionItem.id === ruleItem.position_id);
            option = {
              ...option,
              target_team_status: [
                ...(option.target_team_status ?? []),
                {
                  ...teamStatus,
                  operation: foundOperation,
                  position: findPosition,
                },
              ],
            };
          }
        });

        return option;
      });

      return listStatus as TeamStatusRulesTarget[];
    },
    // eslint-disable-next-line
    [team_credit_status, i18n],
  );

  const getListOfNextTeamStatus = useCallback(
    (
      currentId: string,
      operationId: string,
      positionId: string | null = null,
      likeOptions = false,
      includeCurrent = true,
    ) => {
      const findTeamStatus = team_credit_status?.find(teamStatus => teamStatus.id === currentId);

      if (findTeamStatus) {
        const rules = findTeamStatus.rules_target;
        const listStatusIds = rules
          .filter((rule: TeamStatusRulesData) => {
            return rule.operation_id === operationId && (rule.position_id === positionId || rule.position_id === null);
          })
          .map((rule: TeamStatusRulesData) => rule.team_credit_status_target_id);

        if (listStatusIds.length > 0) {
          const listStatus = team_credit_status
            .filter(teamStatus => (listStatusIds.indexOf(teamStatus.id ?? '') > -1 ? teamStatus : null))
            .filter(teamStatus => teamStatus != null);

          if (likeOptions === true) {
            const listOptions = listStatus.map((teamStatus: TeamStatusData) => ({
              key: teamStatus.id,
              label: getTranslations(teamStatus, 'description', i18n.language, 'name'),
              value: teamStatus.id,
            }));

            if (includeCurrent === false) {
              return listOptions as SelectOptionType[];
            }
            return [getTeamStatus(currentId, true), ...listOptions] as SelectOptionType[];
          }

          if (includeCurrent === false) {
            return listStatus as TeamStatusData[];
          }
          return [getTeamStatus(currentId), ...listStatus] as TeamStatusData[];
        }
      }

      return [];
    },
    // eslint-disable-next-line
    [team_credit_status, getTranslations, i18n],
  );

  async function handleChangeStatusCreditRequest(changeStatusData: ChangeCreditRequestStatusDataProps) {
    setChangeCreditRequestStatusData({
      ...changeStatusData,
    });
    form.reset({
      ...form.getValues(),
      approved_value: changeStatusData.approvedValue ?? 0,
      approved_value_barter: changeStatusData.approvedValueBarter ?? 0,
      approved_value_cash: changeStatusData.approvedValueCash ?? 0,
      code_bp: changeStatusData.providerSapCode ?? '',
      credit_due_date: changeStatusData.creditDueDate ?? '',
      ptax: changeStatusData.ptax ?? 0,
      team_status_id: changeStatusData.currentStatusId ?? '',
      limit_expiration_date: changeStatusData.limitExpirationDate?.toString() ?? '',
      amount_paid: changeStatusData.enabledValue ?? 0,
    });
    setModalVisible(true);
    const status = getTeamStatus(changeStatusData.newStatusId) as TeamStatusData;
    if (!status) {
      console.error('Status not found id:', changeStatusData.newStatusId);
      return alert({
        title: t('error'),
        type: 'error',
        description: t('selected-status-not-found'),
      });
    }
    setTargetStatus(status);
  }

  function handleModalClose() {
    setModalVisible(false);
    setModalLoading(false);
    form.reset(initialCreditStatusChangeFormValues);
    setShouldReloadAfterSuccess(false);
  }

  const fetchSubsidiarySapCode = async () => {
    if (!changeCreditRequestStatusData.providerSapCode) return;
    const response = await getSubsidiarySapCode(changeCreditRequestStatusData.providerSapCode);
    setSubsidiarySapCode(response?.code);
    form.reset({
      ...form.getValues(),
      code_bp: response?.code ?? changeCreditRequestStatusData.providerSapCode,
    });
  };

  const fieldAmount = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let result = [];
    if (targetStatus) {
      if (
        (targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG &&
          (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_MTM_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_INTERN_MARKET_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_PREPAY_ID ||
            changeCreditRequestStatusData.operationId === OPERATION_FERTILIZER_SUPPLIERS_ID)) ||
        (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
          targetStatus.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID)
      ) {
        result.push('enable_limit');
      }
      if (
        targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG ||
        targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID ||
        targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID ||
        targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG ||
        (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
          targetStatus.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID) ||
        (targetStatus.id === TEAM_STATUS_APPROVED_CREDIT_FUTURE_HARVEST_CREDIT &&
          changeCreditRequestStatusData.operationId === OPERATION_PREFIN_ID)
      ) {
        if (changeCreditRequestStatusData.approvedValue) {
          setShouldBlockApprovedValue(true);
          form.setValue('approved_value', changeCreditRequestStatusData.approvedValue);
        }
        result.push('approved_value');
      }

      if (
        changeCreditRequestStatusData?.datasource?.toLowerCase() !== 'datasul' &&
        (targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG ||
          targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID ||
          targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID ||
          targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_SLUG ||
          targetStatus.id === TEAM_STATUS_ADVANCE_PROGRAMMING_CONTRACTS_SOLICITATION_ID ||
          (changeCreditRequestStatusData.operationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
            targetStatus.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID))
      ) {
        result.push('code_bp');
        fetchSubsidiarySapCode();
      }

      if (
        (targetStatus.slug === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_SLUG ||
          targetStatus.id === TEAM_STATUS_CREDIT_APPROVED_CONTRACT_ID) &&
        changeCreditRequestStatusData.operationId === OPERATION_PREPAY_ID
      ) {
        result.push('limit_expiration_date');
        form.setValue(
          'limit_expiration_date',
          (typeof changeCreditRequestStatusData.limitExpirationDate === 'string'
            ? changeCreditRequestStatusData.limitExpirationDate
              ? changeCreditRequestStatusData.limitExpirationDate
              : ''
            : changeCreditRequestStatusData.limitExpirationDate
          )?.toString() ?? '',
        );
      }

      if (
        targetStatus.slug === TEAM_STATUS_LIMIT_ENABLING_CONTRACT_SLUG ||
        targetStatus.id === TEAM_STATUS_LIMIT_ENABLING_CONTRACT_ID
      ) {
        result.push('amount_paid');
      }
    }

    return result;
    // eslint-disable-next-line
  }, [targetStatus]);

  async function handleModalChange() {
    form.handleSubmit(async formValues => {
      setModalLoading(true);

      let formData = new FormData();
      formData = formatValuesToFormData(
        formValues,
        formData,
        ['documents'],
        ['credit_due_date', 'documents', 'team_status_id'],
      );

      if (fieldAmount !== null) {
        if (fieldAmount.includes('approved_value')) {
          // formData.append('ptax', formValues.ptax);
          if (shouldShowLimitInputs) formData.append('approved_value', formValues.approved_value.toString());
          // if (shouldShowLimitBarterInputs) formData.append('approved_value_barter', formValues.approved_value_barter);
          // if (shouldShowLimitCashInputs) formData.append('approved_value_cash', formValues.approved_value_cash);
        }

        if (fieldAmount.includes('enable_limit')) {
          formData.append('credit_due_date', moment(formValues.credit_due_date).format(appConfigs.formatApiDate));
        }

        if (fieldAmount.includes('amount_paid')) {
          formData.append('enabled_value', 'amount_paid');
        }
      }

      formData.append('language', i18n.language);
      formData.append('team_status_id', changeCreditRequestStatusData.newStatusId);
      formData.append('_method', 'PATCH');

      mentionedUserIds.forEach(userId => {
        formData.append('mentions[]', userId);
      });

      formValues.documents.forEach(document => {
        formData.append('documents[]', document.name);
      });

      await creditApi.requests
        .updateStatus(changeCreditRequestStatusData.creditRequestId, formData)
        .then(() => {
          alertStatus(t('hooks.creditStatus.modal-status-success'));

          queryClient.invalidateQueries({
            queryKey: ['credit-request/status-history', changeCreditRequestStatusData.creditRequestId],
          });
          setTeamStatusHasChanged(true);
          queryClient.invalidateQueries({
            queryKey: [`credit-request/kanban`, changeCreditRequestStatusData.currentStatusId],
          });
          queryClient.invalidateQueries({
            queryKey: [`credit-request/kanban`, changeCreditRequestStatusData.newStatusId],
          });
          if (shouldReloadAfterSuccess) {
            if (
              changeCreditRequestStatusData.newStatusId &&
              changeCreditRequestStatusData.operationId !== OPERATION_PREPAY_ID &&
              CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE.includes(changeCreditRequestStatusData.newStatusId)
            ) {
              navigate(`/credit-request/edit/${changeCreditRequestStatusData.creditRequestId}`);
            } else navigate(`/credit-request/view/${changeCreditRequestStatusData.creditRequestId}`);
            setShouldReloadAfterSuccess(false);
          }
        })
        .catch(err => alertStatus(err || t('hooks.creditStatus.modal-status-unknown-error'), 'error'))
        .finally(() => {
          handleModalClose();
        });
      setModalLoading(false);
    })();

    return false;
  }

  const isInvalidTargetStatusAttachmentRule = useMemo(() => {
    if (changeCreditRequestStatusData.currentStatusId) {
      const fromTeamStatus = getTeamStatus(changeCreditRequestStatusData.currentStatusId, false) as TeamStatusData;
      const rules = fromTeamStatus.rules_target;
      const listTeamStatus = rules.filter(
        (rule: TeamStatusRulesData) =>
          rule.operation_id === changeCreditRequestStatusData.operationId &&
          rule.team_credit_status_target_id === targetStatus?.id,
      );
      let targetIsAttachmentRequired = false;
      if (listTeamStatus.length > 0) {
        targetIsAttachmentRequired = Boolean(listTeamStatus[0].is_attachment_required);
      }
      return targetIsAttachmentRequired === true && documents.length === 0;
    }

    return false;
    // eslint-disable-next-line
  }, [
    documents,
    targetStatus,
    changeCreditRequestStatusData.currentStatusId,
    changeCreditRequestStatusData.operationId,
  ]);

  return (
    <CreditStatusContext.Provider
      value={{
        shouldReloadAfterSuccess,
        setShouldReloadAfterSuccess,
        teamStatusHasChanged,
        setTeamStatusHasChanged,
        handleChangeStatusCreditRequest,
        getListOfNextTeamStatus,
        getTeam,
        getCurrencyType,
        getTeamBySlug,
        getOperation,
        getStatus,
        getTeamStatus,
        getTeamsByPhaseId,
        getListOfTeamStatusOrderedByPhase,
        getListOfTeams,
        getListOfTeamsOrdered,
        getPhase,
        getListOfPhasesOrdered,
        getListOfTeamStatus,
        getListOfTeamStatusOrdered,
        getListOfTeamStatusOrderedTargetRules,
        getTeamStatusBySlug,
      }}
    >
      <>
        {children}

        <Dialog
          open={modalVisible}
          onOpenChange={open => {
            setModalVisible(open);
            if (!open) {
              handleModalClose();
            }
          }}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-primary">{t('hooks.creditStatus.modal-status-title')}</DialogTitle>
            </DialogHeader>
            <Spin spinning={modalLoading}>
              <p>
                {t('hooks.creditStatus.modal-status-description-next-status').replace(
                  '{credit_request}',
                  changeCreditRequestStatusData.creditRequestNumber.toString(),
                )}
                {'  '}
                <strong>{targetStatus?.translations ? targetStatus?.translations[i18n.language].title : ''}</strong>
              </p>
              <br />
              <p>{t('hooks.creditStatus.modal-status-note')}</p>
              <br />
              {changeCreditRequestStatusData.requestedAmount > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-requested_amount')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.requestedAmount,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {changeCreditRequestStatusData.requestedAmountBarter > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-requested_amount_barter')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.requestedAmountBarter,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {changeCreditRequestStatusData.requestedAmountCash > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-requested_amount_cash')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.requestedAmountCash,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {changeCreditRequestStatusData.approvedValue > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-approved-value')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.approvedValue,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {changeCreditRequestStatusData.approvedValueBarter > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-approved-value-barter')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.approvedValueBarter,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {changeCreditRequestStatusData.approvedValueCash > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-approved-value-cash')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.approvedValueCash,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {changeCreditRequestStatusData.enabledValue > 0 && (
                <p>
                  {t('hooks.creditStatus.modal-status-limit-enabled')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.enabledValue,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>{' '}
                </p>
              )}
              {fieldAmount.includes('partial_limit') && (
                <p>
                  {t('hooks.creditStatus.modal-status-partial-limit')}:{' '}
                  <b>
                    {Formatter.money(
                      changeCreditRequestStatusData.enabledValue,
                      2,
                      changeCreditRequestStatusData.currencyTypeSlug,
                    )}
                  </b>
                </p>
              )}
              <CreditStatusForm
                changeCreditRequestStatusData={changeCreditRequestStatusData}
                fieldAmount={fieldAmount}
                form={form}
                isFetchingSubsidiarySapCode={isFetchingSubsidiarySapCode}
                shouldShowLimitInputs={shouldShowLimitInputs}
                shouldBlockApprovedValue={shouldBlockApprovedValue}
                isInvalidTargetStatusAttachmentRule={isInvalidTargetStatusAttachmentRule}
                setMentionedUserIds={setMentionedUserIds}
              />
            </Spin>
            <DialogFooter>
              <div className="flex items-center gap-2">
                <Button status="secondary" onClick={() => handleModalClose()} disabled={modalLoading}>
                  {t('hooks.creditStatus.modal-status-buttons.cancel')}
                </Button>

                <Button
                  status="primary"
                  onClick={() => !modalLoading && handleModalChange()}
                  loading={modalLoading}
                  disabled={modalLoading || isInvalidTargetStatusAttachmentRule}
                >
                  {t('hooks.creditStatus.modal-status-buttons.save')}
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
    </CreditStatusContext.Provider>
  );
};

function useCreditStatus(): CreditStatusContextData {
  return useContext(CreditStatusContext);
}

export { CreditStatusProvider, useCreditStatus };
