// import { useFileContext } from 'context/fileContext/fileContext';

import { handleRequest, handleRequestAttachments } from '../services/api/api';
import attachmentApi from '../services/api/attachment';

export const useFetchFiles = () => {
  // const getFileView = async (value: any, setLoadingFile: any) => {
  //   const response = await handleRequest(() => attachmentApi.view(value), setLoadingFile);
  //   return response;
  // };

  const handleGetUrlUpload = async (fileName: string, file: any, setLoadingFile: any) => {
    const urlResponse = await getUrlUpload(fileName, setLoadingFile);
    if (urlResponse === null) {
      return null;
    }
    if (urlResponse) {
      await uploadFile(urlResponse.uri, file, setLoadingFile);
      return urlResponse;
    }
  };

  const getFileDownload = async (value: any, setLoadingFile?: any) => {
    const response = await handleRequest(() => attachmentApi.download(value), setLoadingFile && setLoadingFile);
    return response;
  };

  const getUrlUpload = async (fileName: string, setLoadingFile: any) => {
    const response = await handleRequest(() => attachmentApi.urlUpload(fileName), setLoadingFile);
    return response;
  };

  const uploadFile = async (url: string, file: any, setLoadingFile: any) => {
    setLoadingFile(true);
    const response = await handleRequestAttachments(() => attachmentApi.upload(url, file), setLoadingFile);
    if (response === null) {
      return null;
    }
    setLoadingFile(false);
    return response;
  };

  const deleteFile = async (name: any, setLoadingFile?: any) => {
    const response = await handleRequestAttachments(
      () => attachmentApi.destroy(name),
      setLoadingFile && setLoadingFile,
    );
    return response;
  };

  return {
    // getFileView,
    handleGetUrlUpload,
    getFileDownload,
    deleteFile,
    getUrlUpload,
  };
};
